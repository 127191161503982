import React from 'react'
/*COMPONENTS IMPORTS*/
import { useTranslation, Trans } from "react-i18next";
import Title from 'Common/Components/ArticleTitle/articleTitle';
import SeparationIcon from 'Common/Components/SeparationIcon/separationIcon';
/*CSS IMPORTS*/
import './MaintenanceContent.css'
/*IMAGES IMPORTS*/
import { Maintenance } from 'Images/ImageComponents';

export default function MaintenanceContent() {
    const { t, i18n } = useTranslation();
    const Maintenancepage = "Maintenance_page."
    const managementsystem = "Maintenance_page.section.management_system."
    const managementprogram= "Maintenance_page.section.management_program."
    const managementemployment= "Maintenance_page.section.management_employment."
    const softwaresection= "Maintenance_page.section.software_section."
    return (
        <div className='maintenance-content'>
            <Title>{t(Maintenancepage +"title")} </Title>
            <img src={Maintenance}/>
                <div className='maintenance-text'>
                    <Trans i18nKey={Maintenancepage + "content"} ></Trans>
                    <span>{t(managementsystem +"content")} </span>
                    <ul className='maintenance-taxt'>
                        <li>{t(managementsystem + "point_1")}</li>
                        <li>{t(managementsystem + "point_2")}</li>
                        <li>{t(managementsystem + "point_3")}</li>
                        <li>{t(managementsystem + "point_4")}</li>
                        <li>{t(managementsystem + "point_5")}</li>
                        <li>{t(managementsystem + "point_6")}</li>
                        </ul>
                    <SeparationIcon title={t(managementprogram +"title")} ></SeparationIcon>
                    <ul className='maintenance-taxt'>
                        <li>{t(managementprogram + "point_1")}</li>
                        <li>{t(managementprogram + "point_2")}</li>
                        <li>{t(managementprogram + "point_3")}</li>
                        <li>{t(managementprogram + "point_4")}</li>
                        <li>{t(managementprogram + "point_5")}</li>
                        <li>{t(managementprogram + "point_6")}</li>
                        <li>{t(managementprogram + "point_7")}</li>
                        <li>{t(managementprogram + "point_8")}</li>
                        <li>{t(managementprogram + "point_9")}</li>
                        <li>{t(managementprogram + "point_10")}</li>
                        <li>{t(managementprogram + "point_11")}</li>
                        <li>{t(managementprogram + "point_12")}</li>
                        <li>{t(managementprogram + "point_13")}</li>
                        </ul>
                    <SeparationIcon title={t(managementemployment +"title")} ></SeparationIcon>
                    <ul className='maintenance-taxt'>
                        <li>{t(managementemployment + "point_1")}</li>
                        <li>{t(managementemployment + "point_2")}</li>
                        <li>{t(managementemployment + "point_3")}</li>
                        <li>{t(managementemployment + "point_4")}</li>
                        <li>{t(managementemployment + "point_5")}</li>
                        <li>{t(managementemployment + "point_6")}</li>
                        <li>{t(managementemployment + "point_7")}</li>
                        <li>{t(managementemployment + "point_8")}</li>
                        </ul>
                    <SeparationIcon title={t(softwaresection +"title")}></SeparationIcon>
                    <ul className='maintenance-taxt'>
                        <li>{t(softwaresection + "point_1")}
                        <a href={t(softwaresection + "point_1_url")}> [Details]</a></li>
                        <li>{t(softwaresection + "point_2")}
                        <a href={t(softwaresection + "point_2_url")}>[Details]</a></li>
                        <li>{t(softwaresection + "point_3")}
                        <a href={t(softwaresection + "point_3_url")}> [Details]</a></li>
                        </ul> 
                </div>
        </div>
    )
}
