import React from "react";

import { useTranslation } from "react-i18next";

// Custom Components
import SolutionCards from "Pages/Home/Components/SolutionCards/SolutionCards";
import {
    homepage_solutions_distribution,
    homepage_solutions_finance,
    homepage_solutions_hr,
    homepage_solutions_industry,
    homepage_solutions_others,
    homepage_solutions_realEstate,
} from "Images/ImageComponents";

// Style
import "./smartSolutionSection.css";

//  Translation tag
const homePageSolutions = "home_page.solutions."
const solutions = [
    {
        index: 0,
        image: homepage_solutions_finance,
        title: homePageSolutions + "finance",
    },
    {
        index: 1,
        image: homepage_solutions_hr,
        title: homePageSolutions + "hr",
    },
    {
        index: 2,
        image: homepage_solutions_distribution,
        title: homePageSolutions + "distribution",
    },
    {
        index: 3,
        image: homepage_solutions_realEstate,
        title: homePageSolutions + "real_estate",
    },
    {
        index: 4,
        image: homepage_solutions_industry,
        title: homePageSolutions + "industry",
    },
    {
        index: 5,
        image: homepage_solutions_others,
        title: homePageSolutions + "others",
    },
];

export default function SmartSolutionSection() {
    const { t, i18n } = useTranslation();
    return (
        <div className="smart-solution_container">
            <h2 className="container_title">
                {t(homePageSolutions + "title")}
            </h2>
            <div className="container_solution-list">
                {solutions.map((solution) => (
                    <SolutionCards
                        index={solution.index}
                        image={solution.image}
                        title={t(solution.title)}
                    />
                ))}
            </div>
        </div>
    );
}
