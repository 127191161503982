import React from 'react'

// Style
import "./solutionCards.css"

export default function SolutionCards({index, image, title}) {
    return (
        <div id={index} className='solution-card'>
            <img src={image} alt={`number ${index}`} />
            <h3>{title}</h3>
        </div>
    )
}
