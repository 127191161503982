import { useTranslation } from "react-i18next";
import { about_us_slide1 } from "Images/ImageComponents";
import "./Information.css" 

export default function Information() {
    const { t } = useTranslation();
    return (
        <div className="aboutus-container">
            <img className="introSlide"src={about_us_slide1} title="slide"/>
            <div className="aboutus__contents">
                <h2>{t("about_us.title")}</h2>
                <div className="aboutus__content">
                    <p>{t("about_us.when")}</p>
                </div>
                <div className="aboutus__content">
                    <p>{t("about_us.what")}</p>
                </div>
            </div>
        </div>
    );
}
