import React from 'react'

// Style
import "./solutionsCard.css"

export default function SolutionsCard({ image, name, text }) {
    return (
        <div className='solutions-card'>
            <div className='solutions-card_image'>
                {image}
            </div>
            <div className='solutions-card_content'>
                <span>{name} </span>
                <p>{text} </p>
            </div>
        </div>
    )
}
