import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, 
            faBuilding,
            faDownload,
            faUpload,
            faBoxArchive,
            faRectangleList,
            faMoneyBill1,
            faEnvelope,
            faHandshake,
            faFileInvoice,
            faList} from '@fortawesome/free-solid-svg-icons';


const navigationItems = [
    { key: "financial_intro", 
        icon:<FontAwesomeIcon icon={faList} />,
    },
    { key: "general_ledger", 
        icon:<FontAwesomeIcon icon={faCalculator} />,
    },
    { key: 'fixed_assets', 
        icon:<FontAwesomeIcon icon={faBuilding} />,
    },
    { key: 'accounts_payable', 
        icon:<FontAwesomeIcon icon={faUpload} />,
    },
    { key: 'accounts_receivable', 
        icon:<FontAwesomeIcon icon={faDownload} />,
    },
    { key: 'treasury_management', 
        icon:<FontAwesomeIcon icon={faBoxArchive} />,
    },
    { key: 'banks_printing', 
        icon:<FontAwesomeIcon icon={faRectangleList} />,
    },
    { key: 'petty_cash', 
        icon:<FontAwesomeIcon icon={faMoneyBill1} />,
    },
    { key: 'letters_guarantee', 
        icon:<FontAwesomeIcon icon={faEnvelope} />,
    },
    { key: 'letters_credit', 
        icon:<FontAwesomeIcon icon={faEnvelope} />,
    },
    { key: 'islamic_loans', 
        icon:<FontAwesomeIcon icon={faHandshake} />,
    },
    { key: 'tax_system', 
        icon:<FontAwesomeIcon icon={faFileInvoice} />,
    },
];


export default navigationItems;