import React, {useEffect} from 'react'

/*COMPONENTS IMPORTS*/
import Article from 'Pages/Construction/Components/Article/article'
import Sidebar from 'Common/Components/Sidebar/sidebar'
/*CSS IMPORTS*/
import 'mainStyle.css'
import 'Pages/Construction/construction.css'
import 'Pages/Construction/indexResponsive.css'

export default function Construction() {
  useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Construction | ASCON";
  });
  return (
    <>
      <div className='container'>
        <div className='article'>
          <Article></Article>
        </div>
        <div className='sidebar'>
          <Sidebar></Sidebar>
        </div>
      </div>
    </>
  )
}
