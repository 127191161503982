import React from 'react'
/*CSS IMPORTS*/
import 'Common/Components/SocialIcons/socialIcons.css'
/*ICONS IMPORTS*/
import FacebookIcon from 'Images/Icons/facebook-icon.png'
import TwitterIcon from 'Images/Icons/twitter-icon.png'
import InstagramIcon from 'Images/Icons/instagram-icon.png'
import YoutubeIcon from 'Images/Icons/youtube-icon.png'
import LinkedinIcon from 'Images/Icons/linkedin-icon.png'

const SocialIcons = () => {
  const FACEBOOK_URL = "https://web.facebook.com/Asconarabia";
  const TWITTER_URL = "http://twitter.com/Asconarabia1";
  const INSTAGRAM_URL = "http://www.instagram.com/Asconarabia";
  const YOUTUBE_URL = "https://www.youtube.com/channel/UCsTJa38ERvvQRpmCXmh7hHg";
  const LINKEDIN_URL = "http://linkedin.com/company/Asconarabia";
  return (
    <div className='socail__bar'>
      <a href={FACEBOOK_URL} target="_blank" rel="noreferrer"><img src={FacebookIcon} alt='facebook-icon' className='social__icon' /></a>
      <a href={TWITTER_URL} target="_blank" rel="noreferrer"><img src={TwitterIcon} alt='facebook-icon' className='social__icon' /></a>
      <a href={INSTAGRAM_URL} target="_blank" rel="noreferrer"><img src={InstagramIcon} alt='facebook-icon' className='social__icon' /></a>
      <a href={YOUTUBE_URL} target="_blank" rel="noreferrer"><img src={YoutubeIcon} alt='facebook-icon' className='social__icon' /></a>
      <a href={LINKEDIN_URL} target="_blank" rel="noreferrer"><img src={LinkedinIcon} alt='facebook-icon' className='social__icon' /></a>
    </div>)
}

export default SocialIcons