import React , { useEffect } from 'react';
/*COMPONENTS IMPORTS*/
import CommercialContent from './components/CommercialContent';
import Sidebar from 'Common/Components/Sidebar/sidebar';
/*CSS IMPORTS*/
import "./Commercial.css";
import "./IndexResponsive.css";

export default function Commercial() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Commercial | ASCON";
    });

    return (
    <div className='Commercial'>
        <div className='Commercial__content'>
            <CommercialContent></CommercialContent>
        </div>
        <div className='Commercial__sidebar'>
                <Sidebar></Sidebar>
            </div>
    </div>
    )
}
