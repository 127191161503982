import React from 'react'

// Style
import "./partners-cards.css"

export default function PartnersCard({ image, name, text }) {
    return (
        <div className='partners-card'>
            <div className='partners-card_image'>
                <img src={image} alt={name} />
            </div>
            <div className='partners-card_content'>
                <span>{name} </span>
                <p>{text} </p>
            </div>
            <div className='partners-card_line-separator'></div>
        </div>
    )
}
