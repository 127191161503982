import React, { useEffect, useRef } from 'react'
/*ICONS IMPORTS*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
/*CSS IMPORTS*/
import 'Common/Components/ContactForm/contactForm.css'
import 'Common/Components/ContactForm/contactFormResponsive.css'
/*DATA IMPORTS*/
import countriesData from 'Common/Context/countries/countriesData.json'

import { useTranslation, Trans } from "react-i18next";
import Cookies from 'js-cookie';
import emailjs from '@emailjs/browser';


const ContactForm = () => {

  const { t, i18n } = useTranslation();
  const contactForm = "components.contact_form."

  const [formStatus, setFormStatus] = React.useState("")
  
  const onSubmit = (e) => {
    e.preventDefault();

    // Handle form submission
    setFormStatus('Submit')
    const { name, email, message, phoneNumber, country } = e.target.elements

    emailjs.send("service_x763crj","template_fmmms8t",{
      from_name: name.value,
      customer_name: name.value,
      customer_message: message.value,
      customer_email: email.value,
      customer_phoneNumber: phoneNumber.value,
      customer_country: country.value,
      reply_to: "info@tovmo.com",
      }, {
        publicKey: '5GcS1AJBSrKCOKCYz',
      }).then((result) => {
        alert('Inquiry submitted successfully!');
      }, (error) => {
        alert('Failed to send the inquiry. Please try again later.');
      });

    e.target.reset();
  };

  const myRefName = useRef()
  const myRefEmail = useRef()
  const myRefPhone = useRef()
  const myRefQuestion = useRef()

  const currentLanguageCode = Cookies.get('i18next') || 'en';

  useEffect(() => {
    setFormStatus(t(contactForm + "submit"))
  }, [t])

  //  Chaning floating labels' positions after changing languages

  useEffect(() => {
    if (currentLanguageCode === "en") {
      myRefName.current.style = "left:5%;";
      myRefEmail.current.style = "left:25%;";
      myRefPhone.current.style = "left:25%;";
      myRefQuestion.current.style = "left:25%;";
    } else {
      myRefName.current.style = "left:80%;";
      myRefEmail.current.style = "left:32%;";
      myRefPhone.current.style = "left:55%;";
      myRefQuestion.current.style = "left:40%;";
    }
  }, [t])



  return (
    <form onSubmit={onSubmit} className='input__form'>
      <div className='input__area__wrp'>
        <br />
        <input type="text" className="input__area--name" id="name" required />
        <span className="label__floating--name" ref={myRefName} >{t(contactForm + "name")}</span>
      </div>

      <table className='input__area__wrp'>
        <tr>
          <th className='th__left'>
            <FontAwesomeIcon icon={faEnvelope} className='input__area__icon' pull="left" />
          </th>
          <th className='th__right'>
            <input type="text" className="input__area" id="email" required />
            <label className='label__floating' ref={myRefEmail}>{t(contactForm + "email")}</label>
          </th>
        </tr>
      </table>

      <table className='input__area__wrp'>
        <tr>
          <th className='th__left'>
            <FontAwesomeIcon icon={faPhoneFlip} className='input__area__icon' pull="left" />
          </th>
          <th className='th__right'>
            <div>
              <input type="text" className="input__area" id="phoneNumber" required />
              <label className='label__floating' ref={myRefPhone}>{t(contactForm + "phone")}</label>
            </div>
          </th>
        </tr>
      </table>

      <table className='input__area__wrp'>
        <tr>
          <th className='th__left'>
            <FontAwesomeIcon icon={faGlobe} className='input__area__icon' pull="left" />
          </th>
          <th className='th__right'>
            <select name="country" className="select" required>
              <option className='label__floating' value="" disabled selected>{t(contactForm + "country")}</option>
              {countriesData.map(ctr => (
                <option value={ctr.name}>{ctr.name}</option>
              ))}
            </select>
          </th>
        </tr>
      </table>

      <table className='input__area__wrp'>
        <tr>
          <th className='th__left'>
            <FontAwesomeIcon icon={faQuestion} className='input__area__icon' pull="left" />
          </th>
          <th className='th__right'>
            <textarea className='message__area' id="message" required ></textarea>
            <label className='label__floating--textarea' ref={myRefQuestion}>{t(contactForm + "questions")}</label>
          </th>
        </tr>
      </table>

      <div className='button__containter'>
        <button className='button' type="submit">
          {formStatus}
        </button>
      </div>
    </form>
  )
};
export default ContactForm;
