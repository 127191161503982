import React from 'react'
import 'Pages/Careers/Components/Title/title.css'

function Title(props) {
    // const Title = 'Join ASCON'
    return (
        <>
            <div className='title-container'>
                {props.title}
            </div>
        </>
    )
}

export default Title