import React, {useEffect} from 'react'

// Custom Components
import SideNav from 'Common/Components/SideNav/SideNav';
import ContactForm from 'Common/Components/ContactForm/contactForm';
import SocialIcons from 'Common/Components/SocialIcons/socialIcons';

// Image & Context
import { human_systems_intro } from 'Images/ImageComponents';
import  navigationItems  from "./Context/items"

// Styles
import './humanSystems.css'; 



export default function HumanResources ()  {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Human Resources | ASCON";
    });

    return (
        <div className="human-container">
            <img src={human_systems_intro} alt='human image' />
            
            <div className='human_content'>
                <div>
                    <div className='human-container_intro'>
                        <h2>ASCON’s HR System Group</h2>
                        <p>For every business, there are multiple keys to success. One of the most important of these keys is your employees. If you manage your employees correctly and provide them with the information and systems they need to perform their jobs; you can see the business going smoothly and profits rising.</p>
                        <p>It is easy to say, but it is not so easy to implement, as managing employees effectively can be a real challenge. This is where the importance of ERP or HRM programs becomes more specific.</p>
                        <p>ASCON provides you with the most potent HRM Software, which will help you manage employees’ affairs in your organization efficiently.</p>
                        <p>The range of HR systems from ASCON covers all of your organization’s needs for managing your personnel affairs.</p>
                    </div>
                    <SideNav navigationItems={navigationItems}  page={"human_resources"}/>
                </div>
                <div className='content_right-side'>
                    <ContactForm />
                    <div className='contact-page_divider ' />
                    <SocialIcons />
                    <div className='contact-page_divider ' />
                </div>
            </div>
        </div>
    );
};

