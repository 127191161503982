import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, 
            faCubes,
            faSquare,
            faCartPlus,
            faBullhorn,
            faIdBadge,
            faTruck,
            faList} from '@fortawesome/free-solid-svg-icons';


const navigationItems = [
    { key: "distribution_intro", 
        icon:<FontAwesomeIcon icon={faList} />,
    },
    { key: "sales_control", 
        icon:<FontAwesomeIcon icon={faChartLine} />,
    },
    { key: 'stock_control', 
        icon:<FontAwesomeIcon icon={faCubes} />,
    },
    { key: 'point_sales', 
        icon:<FontAwesomeIcon icon={faSquare} />,
    },
    { key: 'purchasing', 
        icon:<FontAwesomeIcon icon={faCartPlus} />,
    },
    { key: 'offers_discounts', 
        icon:<FontAwesomeIcon icon={faBullhorn} />,
    },
    { key: 'loyalty_program', 
        icon:<FontAwesomeIcon icon={faIdBadge} />,
    },
    { key: 'automobile_sales_sys', 
        icon:<FontAwesomeIcon icon={faTruck} />,
    },
];


export default navigationItems;