import React, { useEffect } from "react";

// Custom Components
import SideNav from "Common/Components/SideNav/SideNav";
import ContactForm from "Common/Components/ContactForm/contactForm";
import SocialIcons from "Common/Components/SocialIcons/socialIcons";

// Image & Context
import { featured_systems_intro } from "Images/ImageComponents";
import navigationItems from "./Context/items";

// Styles
import "./featuredSystems.css";

export default function FeaturedSystems() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Featured Systems | ASCON";
    });
    return (
        <div className="featured-container">
            <img src={featured_systems_intro} alt="featured img" />
            <div className="featured_content">
                <SideNav
                    navigationItems={navigationItems}
                    page={"featured_systems"}
                />
                <div className="content_right-side">
                    <ContactForm />
                    <div className="contact-page_divider " />
                    <SocialIcons />
                    <div className="contact-page_divider " />
                </div>
            </div>
        </div>
    );
}
