import React from 'react'
import { useTranslation, Trans } from "react-i18next";
import Title from 'Common/Components/ArticleTitle/articleTitle'
import SeparationIcon from 'Common/Components/SeparationIcon/separationIcon';
import "./CommercialContent.css";
import commercial from "Images/Background/commercial.png";

export default function CommercialContent() {
    const { t, i18n } = useTranslation();
    const commercialpage ="commercial__page."
    const systemadvantages ="commercial__page.section.system_advantages."
    const softwareSection = "commercial__page.section.software_section."
    const specializedsystems ="commercial__page.section.specialized_systems."
    return (
    <div className='commercial-content'>
        <Title>{t(commercialpage +"title")}</Title>
        <img src={commercial} alt="commercial Image" /> 
        <div className='commerical__text'>
            <Trans i18nKey={commercialpage + "page_content"}>
            <a href={t(commercialpage + "page_content_url")}></a>
            <div className='commercial__break' />
            </Trans>

        <SeparationIcon title={t(systemadvantages +"title")}></SeparationIcon>
        <ul className='commerical__text'>
            <li>{t(systemadvantages + "point_1")}</li>
            <li>{t(systemadvantages + "point_2")}</li>
            <li>{t(systemadvantages + "point_3")}</li>
            <li>{t(systemadvantages + "point_4")}</li>
            <li>{t(systemadvantages + "point_5")}</li>
            <li>{t(systemadvantages + "point_6")}</li>
            <li>{t(systemadvantages + "point_7")}</li>
            <li>{t(systemadvantages + "point_8")}</li>
            <li>{t(systemadvantages + "point_9")}</li>
            <li>{t(systemadvantages + "point_10")}</li>
            <li>{t(systemadvantages+ "point_11")}</li>
            <li>{t(systemadvantages + "point_12")}</li>
            <li>{t(systemadvantages + "point_13")}</li>
            <li>{t(systemadvantages + "point_14")}</li>
            <li>{t(systemadvantages + "point_15")}</li>
            <li>{t(systemadvantages + "point_16")}</li>
    </ul>
    <SeparationIcon title={t(softwareSection +"title")}></SeparationIcon>
    
    <ul className='commerical__text'>
            <li>{t(softwareSection + "point_1")}
                <a href={t(softwareSection + "point_1_url")}> [Details]</a>
            </li>
            <li>{t(softwareSection + "point_2")}
                <a href={t(softwareSection + "point_2_url")}>[Details]</a>
            </li>
            <li>{t(softwareSection + "point_3")}
                <a href={t(softwareSection + "point_3_url")}> [Details]</a>
            </li>
    </ul> 

    <SeparationIcon title={t(specializedsystems +"title")}></SeparationIcon>
    <ul className='commerical__text'>
            <li>{t(specializedsystems + "point_1")}
                <a href={t(specializedsystems + "point_1_url")}> [Details]</a>
            </li>
            <li>{t(specializedsystems + "point_2")}
                <a href={t(specializedsystems + "point_2_url")}>[Details]</a>
            </li>
            <li>{t(specializedsystems + "point_3")}
                <a href={t(specializedsystems + "point_3_url")}> [Details]</a>
            </li>
            <li>{t(specializedsystems + "point_4")}
            </li>
    </ul> 
        </div>
    </div>
    )
}
