import React from 'react'
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Style
import "./companyInfo.css"

export default function CompanyInfo({ name, phones, website, email,location, info}) {
    return (
        <div className='company-info'>
            <span>{name}</span>
            {/* <h2>{name}</h2> */}
            <ul className='company-info__contact-info'>
                {location && <li >
                    <FontAwesomeIcon icon={faLocationDot} className='company-info__contact-into_icon' pull="left" />
                    {location}
                </li>}
                {phones && <li >
                    <FontAwesomeIcon icon={faPhoneFlip} className='company-info__contact-into_icon' pull="left" />
                    <ul className='company-info__phones'>
                        {phones.map(phone => (<li >{phone}</li>))}
                    </ul>
                </li>}
                {email && <li >
                    <FontAwesomeIcon icon={faEnvelope} className='company-info__contact-into_icon' pull="left" />
                    {email}
                </li>}
                {website && <li >
                    <FontAwesomeIcon icon={faGlobe} className='company-info__contact-into_icon' pull="left" />
                    <a href={`http://${website}`} target=' blank'>{website}</a>
                </li>}
            </ul>
            <p>{info}</p>
        </div>
    )
}
