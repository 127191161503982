import React from 'react'
/*COMPONENTS IMPORTS*/
import { useTranslation, Trans } from "react-i18next";
import Title from 'Common/Components/ArticleTitle/articleTitle';
import SeparationIcon from 'Common/Components/SeparationIcon/separationIcon';
import TabBar from 'Common/Components/TabBar/TabBar';
import Tab from 'Common/Components/Tab/Tab';
/*CSS IMPORTS*/
import './PharmaciesContent.css';
/*IMAGES IMPORTS*/
import { Pharmacies } from 'Images/ImageComponents';
export default function PharmaciesContent() {
    const { t, i18n } = useTranslation();
    const Pharmaciespage ="Pharmacies_page."
    const wasfaty = "Pharmacies_page.section.wasfaty."
    const rasa = "Pharmacies_page.section.rasa."
    const managementsystem ="Pharmacies_page.section.management_system."
    const softwaresection ="Pharmacies_page.section.software_section."
    const specializedsystems ="Pharmacies_page.section.specialized_systems."
    return (
        <div className='pharmacies-content'>
            <Title>{t(Pharmaciespage +"title")}</Title>
                <span>{t(Pharmaciespage +"title_1")}</span>
                <img src={Pharmacies}/>
                <div className='pharmacies-text'>
            <Trans i18nKey={Pharmaciespage + "content"} ></Trans>
            <div className='pharmacy_tabBar'>
            <TabBar>
                <Tab title={t(wasfaty+ "title")}defaultTab="checked" id="tab1">
                {t(wasfaty+ "content")}</Tab>
                <Tab title={t(rasa+ "title")} id="tab2">
                {t(rasa+ "content")}</Tab>
            </TabBar>
            </div>
            <span>{t(managementsystem + "title")}</span>
                <ul className='pharmacies-text'>
                <li>{t(managementsystem + "point_1")}</li>
                <li>{t(managementsystem + "point_2")}</li>
                <li>{t(managementsystem + "point_3")}</li>
                <li>{t(managementsystem + "point_4")}</li>
                <li>{t(managementsystem + "point_5")}</li>
                <li>{t(managementsystem + "point_6")}</li>
                <li>{t(managementsystem + "point_7")}</li>
                <li>{t(managementsystem + "point_8")}</li>
                <li>{t(managementsystem + "point_9")}</li>
                <li>{t(managementsystem + "point_10")}</li>
                <li>{t(managementsystem + "point_11")}</li>
                <li>{t(managementsystem + "point_12")}</li>
                <li>{t(managementsystem + "point_13")}</li>
                <li>{t(managementsystem + "point_14")}</li>
                <li>{t(managementsystem + "point_15")}</li>
                <li>{t(managementsystem + "point_16")}</li>
                <li>{t(managementsystem + "point_17")}</li>
                <li>{t(managementsystem + "point_18")}</li>
                <li>{t(managementsystem + "point_19")}</li>
                <li><Trans i18nKey={managementsystem + "point_20"} >
                    <a href={t(managementsystem+ "point_20_url")}/></Trans></li>
                </ul>
            <SeparationIcon title={t(softwaresection +"title")}></SeparationIcon>
                <ul className='pharmacies-text'>
                    <li>{t(softwaresection + "point_1")}
                        <a href={t(softwaresection + "point_1_url")}> [Details]</a></li>
                    <li>{t(softwaresection + "point_2")}
                        <a href={t(softwaresection + "point_2_url")}>[Details]</a></li>
                    <li>{t(softwaresection + "point_3")}
                        <a href={t(softwaresection + "point_3_url")}> [Details]</a></li>
                    </ul> 
            <SeparationIcon title={t(specializedsystems +"title")}></SeparationIcon>
                <ul className='industrial__text'>
                    <li>{t(specializedsystems + "point_1")}
                        <a href={t(specializedsystems + "point_1_url")}> [Details]</a></li>
                    <li>{t(specializedsystems + "point_2")}
                        <a href={t(specializedsystems + "point_2_url")}>[Details]</a></li>
                    <li>{t(specializedsystems + "point_3")}
                        <a href={t(specializedsystems + "point_3_url")}> [Details]</a></li>
                    <li>{t(specializedsystems + "point_4")}
                        <a href={t(specializedsystems + "point_4_url")}> [Details]</a></li>
                    <li>{t(specializedsystems + "point_5")}
                        <a href={t(specializedsystems + "point_5_url")}> [Details]</a></li>
                    </ul> 
            </div>
            
        </div>
    )
}
