import React from 'react'
import { useTranslation } from "react-i18next";
/*CSS IMPORTS*/
import "./AdvantagesSection.css"
/*IMAGES IMPORTS*/
import {
    ascon_advantage1,
    ascon_advantage2,
    ascon_advantage3,
} from "Images/ImageComponents"

export default function AdvantagesSection() {
    const { t, i18n } = useTranslation();
    const homePageAdvantages = "home_page.advantages."
    return (
        <div className='advantages-section'>
            <div className='advantages-section_box'>
                <img src={ascon_advantage1} />
                    <h1 className='advantages-section_h1'>
                        {t(homePageAdvantages +"title_1")}</h1>
                    <p className='advantages-section_p'>
                        {t(homePageAdvantages +"content_1")}</p>
            </div>
            <div className='advantages-section_box'>
                <img src={ascon_advantage2} />
                    <h1 className='advantages-section_h1'>
                        {t(homePageAdvantages +"title_2")}</h1>
                    <p className='advantages-section_p'>
                        {t(homePageAdvantages +"content_2")}</p>
            </div>
            <div className='advantages-section_box'>
                <img src={ascon_advantage3} />
                    <h1 className='advantages-section_h1'>
                        {t(homePageAdvantages +"title_3")}</h1>
                    <p className='advantages-section_p'>
                        {t(homePageAdvantages +"content_3")}</p>
            </div>
    </div>
    )
}
