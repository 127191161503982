import React from 'react'
/*CSS IMPORTS*/
import 'Common/Components/SeparationIcon/separationIcon.css'
/*ICONS IMPORTS*/
import PlayIcon from 'Images/Icons/play-icon.png'

const SeparationIcon = (props) => {
  return (
    <>
      <div className='section'>
        <img src={PlayIcon} className="section__icon" alt='title--icon' />
        {props.title}
      </div>
    </>
  )
}

export default SeparationIcon