import React, { useEffect } from "react";
//custom component
import Information from "./Sections/Information/Information";
import Activities from "./Sections/Activities/Activities";
import Features from "./Sections/Features/Features";
import "./indexResponsive.css";
//style
import "./AboutUs.css";
export default function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "About Us | ASCON";
    });
    return (
        <div className="aboutus">
            <Information />
            <Activities />
            <Features />
        </div>
    );
}
