import React, {useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Custom Component
import SolutionsCard from './Components/SolutionsCard/SolutionsCard'
import Title from 'Common/Components/ArticleTitle/articleTitle'

// Images & icons
import { solutions_intro } from 'Images/ImageComponents'
import { faWindowRestore } from '@fortawesome/fontawesome-free-regular'
import { faEnvelopeOpen } from '@fortawesome/fontawesome-free-regular'
import { faChartColumn } from '@fortawesome/free-solid-svg-icons'
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons'
import { faGears } from '@fortawesome/free-solid-svg-icons'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

import { useTranslation } from "react-i18next";

// Style
import "./solutions.css"

export default function Solutions() {
    const { t, i18n } = useTranslation();
    const solutionsPage = "solutions_page."

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Solutions | ASCON";
    });

    return (
        <div className='solutions-page'>
            <img src={solutions_intro} alt='Intro Img' />
            <Title><h1>{t(solutionsPage + "title")}</h1></Title>
            <div className='solutions_cards'>
                <SolutionsCard
                    image={<FontAwesomeIcon icon={faWindowRestore} className='solution-card_icon' />}
                    name={t(solutionsPage + "reporting_panel_system.title")}
                    text={t(solutionsPage + "reporting_panel_system.content")}
                />
                <SolutionsCard
                    image={<FontAwesomeIcon icon={faChartColumn} className='solution-card_icon' />}
                    name={t(solutionsPage + "report_designer.title")}
                    text={t(solutionsPage + "report_designer.content")}
                />
                <SolutionsCard
                    image={<FontAwesomeIcon icon={faShieldHalved} className='solution-card_icon' />}
                    name={t(solutionsPage + "security_system.title")}
                    text={t(solutionsPage + "security_system.content")}
                />
                <SolutionsCard
                    image={<FontAwesomeIcon icon={faGears} className='solution-card_icon' />}
                    name={t(solutionsPage + "workflow_systems.title")}
                    text={t(solutionsPage + "workflow_systems.content")}
                />
                <SolutionsCard
                    image={<FontAwesomeIcon icon={faCommentDots} className='solution-card_icon' />}
                    name={t(solutionsPage + "sms_system.title")}
                    text={t(solutionsPage + "sms_system.content")}
                />
                <SolutionsCard
                    image={<FontAwesomeIcon icon={faUsers} className='solution-card_icon' />}
                    name={t(solutionsPage + "monitoring_system.title")}
                    text={t(solutionsPage + "monitoring_system.content")}
                />
                <SolutionsCard
                    image={<FontAwesomeIcon icon={faEnvelopeOpen} className='solution-card_icon' />}
                    name={t(solutionsPage + "email_system.title")}
                    text={t(solutionsPage + "email_system.content")}
                />
            </div>
        </div>
    )
}
