import React, {useEffect} from 'react'

import TabBar from 'Common/Components/TabBar/TabBar'
import Tab from 'Common/Components/Tab/Tab'
import Title from 'Pages/Careers/Components/Title/Title'
import JobApplicationForm from 'Common/Components/JobApplicationForm/JobApplicationForm'

import 'Pages/Partners/index.css'
import 'Pages/Partners/indexResponsive.css'

import { useTranslation } from "react-i18next";
import PartnersCard from './Components/Partners_Cards/PartnersCards'
import { tasc_logo_partner, evet_logo_partner, magera_logo_partner, rank_logo_partner } from 'Images/ImageComponents'
import CompanyInfo from './Components/CompanyInfo/CompanyInfo'


export default function Partners() {
    const { t, i18n } = useTranslation();
    const contactInfoSection = "contact_us_page.contact_info."

    const PartnersFinder = "partners_finder."

    const careersPage = "careers_page."
    const salesTabs = "careers_page.tabs.sales_tab."
    const appDevelopersTab = "careers_page.tabs.application_developers_tab."
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Partners | ASCON";
    });
    return (
        <>
            {/* <Title title={"ASCON's Partners"} /> */}
            <div className='partners__title'>{t(PartnersFinder + "title")}</div>
            <div className='partners__container'>
                <div className='partners__body'>
                    <TabBar>
                    <Tab title={t(PartnersFinder + "tiers_title.all_partners")} defaultTab="checked" id="tab1">
                            <div className='partners__tab'>
                                <PartnersCard 
                                    name="Majera"
                                    image={magera_logo_partner}
                                    text={<CompanyInfo
                                            name={""}
                                            phones={["+20 (100) 967 47 27"]}
                                            location={t(PartnersFinder + "company_info.majera.location")}
                                            info={t(PartnersFinder + "company_info.majera.info")}
                                        />}
                                    />
                                <PartnersCard 
                                    name="Rank Solutions"
                                    image={rank_logo_partner}
                                    text={<CompanyInfo
                                            name={""}
                                            phones={["+20 (359) 229 200"]}
                                            website={"rank-sol.com"}
                                            email={"rank@rank-sol.com"}
                                            location={t(PartnersFinder + "company_info.rank_solutions.location")}
                                            info={t(PartnersFinder + "company_info.rank_solutions.info")}
                                        />}
                                    />
                                <PartnersCard 
                                    name="Tasc Inc."
                                    image={tasc_logo_partner}
                                    text={<CompanyInfo
                                            name={""}
                                            phones={["+90 (530) 835 50 30"]}
                                            website={"tascerp.com"}
                                            email={"info@tascerp.com"}
                                            location={t(PartnersFinder + "company_info.tasc.location")}
                                            info={t(PartnersFinder + "company_info.tasc.info")}
                                        />}
                                    />
                                    <PartnersCard 
                                    name="EVET "
                                    image={evet_logo_partner}
                                    text={<CompanyInfo
                                            name={""}
                                            phones={["+90 (212) 777 72 36"]}
                                            location={t(PartnersFinder + "company_info.evet.location")}
                                            info={t(PartnersFinder + "company_info.evet.info")}
                                        />}
                                    />
                            </div>
                        </Tab>
                        <Tab title={t(PartnersFinder + "tiers_title.platinum_partner")}  id="tab2">
                            <div className='partners__tab'>
                                <PartnersCard 
                                    name="Majera"
                                    image={magera_logo_partner}
                                    text={<CompanyInfo
                                            name={""}
                                            phones={["+20 (100) 967 47 27"]}
                                            location={t(PartnersFinder + "company_info.majera.location")}
                                            info={t(PartnersFinder + "company_info.majera.info")}
                                        />}
                                    />
                                    
                            </div>
                        </Tab>
                        <Tab title={t(PartnersFinder + "tiers_title.gold_partner")} id="tab3">
                        <div className='partners__tab'>
                                
                                <PartnersCard 
                                    name="Rank Solutions"
                                    image={rank_logo_partner}
                                    text={<CompanyInfo
                                            name={""}
                                            phones={["+20 (359) 229 200"]}
                                            website={"rank-sol.com"}
                                            email={"rank@rank-sol.com"}
                                            location={t(PartnersFinder + "company_info.rank_solutions.location")}
                                            info={t(PartnersFinder + "company_info.rank_solutions.info")}
                                        />}
                                    />
                                <PartnersCard 
                                    name="Tasc Inc."
                                    image={tasc_logo_partner}
                                    text={<CompanyInfo
                                            name={""}
                                            phones={["+90 (530) 835 50 30"]}
                                            website={"tascerp.com"}
                                            email={"info@tascerp.com"}
                                            location={t(PartnersFinder + "company_info.tasc.location")}
                                            info={t(PartnersFinder + "company_info.tasc.info")}
                                        />}
                                    />
                            </div>
                        </Tab>
                        <Tab title={t(PartnersFinder + "tiers_title.silver_partner")} id="tab4">
                        <div className='partners__tab'>
                                    <PartnersCard 
                                    name="EVET "
                                    image={evet_logo_partner}
                                    text={<CompanyInfo
                                            name={""}
                                            phones={["+90 (212) 777 72 36"]}
                                            location={t(PartnersFinder + "company_info.evet.location")}
                                            info={t(PartnersFinder + "company_info.evet.info")}
                                        />}
                                    />
                            </div>
                        </Tab>
                        <Tab title={t(PartnersFinder + "tiers_title.sales_partner")} id="tab5">
                        <div className='partners__tab'>
                                {/* <PartnersCard 
                                    name="Tasc Inc."
                                    image={tasc_logo_partner}
                                    text={<CompanyInfo
                                            name={""}
                                            phones={["+90 (530) 835 50 30"]}
                                            website={"tascerp.com"}
                                            email={"info@tascerp.com"}
                                        />}
                                    /> */}
                            </div>
                        </Tab>
                    </TabBar>
                </div>
            </div>
        </>
    )
}