import React from 'react'

// Style
import "./countySection.css"


export default function CountrySection( {name, imageSrc ,children}) {
    return (
        <div className='country-section_container'>
            <img className='county-flag' src={imageSrc} alt={name} />
            <div className='country-section_info'>
                <h1>{name}</h1>
                <div className='country-section_cities'>{children}</div>
            </div>
        </div>
    )
}
