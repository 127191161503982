import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

// Custom Components
import {
    homepage_slider1,
    homepage_slider2,
    homepage_slider3,
} from "../../../../Images/ImageComponents";

// Style
import "./slider.css";
import Contact from "Pages/Contact/Contact";

//  Translation tag
const homePage = "home_page.";

const slides = [
    {
        index: 0,
        theme: "light",
        image: homepage_slider1,
        title: homePage + "sliders.slider_1.title",
        text: homePage + "sliders.slider_1.content",
    },
    {
        index: 1,
        theme: "light",
        image: homepage_slider2,
        title: homePage + "sliders.slider_2.title",
        text: homePage + "sliders.slider_2.content",
    },
    {
        index: 2,
        theme: "dark",
        image: homepage_slider3,
        title: homePage + "sliders.slider_3.title",
        text: homePage + "sliders.slider_3.content",
    },
];

function Slider() {
    const { t, i18n } = useTranslation();

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((currentSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
    };

    useEffect(() => {
        const slideInterval = setInterval(nextSlide, 5000);

        return () => {
            clearInterval(slideInterval);
        };
    }, [currentSlide]);

    return (
        <div className="slider-container">
            <button className="prev-btn" onClick={prevSlide}>
                &lt;
            </button>
            <div className="slider">
                {slides.map((slide) => (
                    <div
                        key={slide.index}
                        className={`slide ${
                            slide.index === currentSlide ? "active" : ""
                        }`}>
                        <img
                            src={slide.image}
                            alt={`Slide ${slide.index + 1}`}
                        />
                        <div className="slide_content">
                            <div className={`content_card ${slide.theme}`}>
                                <span>{t(slide.title)}</span>
                                <p>{t(slide.text)}</p>
                                <Link to="/contact">
                                    <button >{t("home_page.contact_us")}</button>
                                </Link>
                            </div>
                        </div>
                        <div className="slide_content-positioning"></div>
                    </div>
                ))}
            </div>
            <button className="next-btn" onClick={nextSlide}>
                &gt;
            </button>
        </div>
    );
}

export default Slider;