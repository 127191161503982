import React, {useEffect} from 'react'

import TabBar from 'Common/Components/TabBar/TabBar'
import Tab from 'Common/Components/Tab/Tab'
import Title from 'Pages/Careers/Components/Title/Title'
import JobApplicationForm from 'Common/Components/JobApplicationForm/JobApplicationForm'

import 'Pages/Careers/index.css'
import 'Pages/Careers/indexResponsive.css'

import { useTranslation } from "react-i18next";


export default function Careers() {
    const { t, i18n } = useTranslation();

    const careersPage = "careers_page."
    const salesTabs = "careers_page.tabs.sales_tab."
    const appDevelopersTab = "careers_page.tabs.application_developers_tab."
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Careers | ASCON";
    });
    return (
        <>
            <Title title={t(careersPage + "title")} />
            <div className='careers__container'>
                <div className='careers__body'>
                    <TabBar>
                        <Tab title={t(salesTabs + "title")} defaultTab="checked" id="tab1">
                            <div className='title--red'>{t(salesTabs + "sub_title")}</div>
                            <div className='title--black'>{t(salesTabs + "description.title")}</div>
                            <div className='text_style'>
                                {t(salesTabs + "description.content")}
                            </div>

                            <div className='title--black'> {t(salesTabs + "special_requirments.title")}</div>
                            <div className='text_style'>
                                {t(salesTabs + "special_requirments.content")}
                            </div>

                        </Tab>
                        <Tab title={t(appDevelopersTab + "title")} id="tab2">
                            <div className='title--red'>{t(appDevelopersTab + "sub_title")}</div>
                            <div className='title--black'>{t(appDevelopersTab + "description.title")}</div>
                            <div className='text_style'>
                                {t(appDevelopersTab + "description.content")}
                            </div>
                            <ul>
                                <li>
                                    {t(appDevelopersTab + "description.bullet_point_1")}
                                </li>
                                <li>
                                    {t(appDevelopersTab + "description.bullet_point_2")}
                                </li>
                                <li>
                                    {t(appDevelopersTab + "description.bullet_point_3")}
                                </li>
                                <li>
                                    {t(appDevelopersTab + "description.bullet_point_4")}
                                </li>
                            </ul>

                            <div className='title--black'> {t(appDevelopersTab + "requirments.title")}</div>
                            <ul>
                                <li>
                                    {t(appDevelopersTab + "requirments.bullet_point_1")}
                                </li>
                                <li>
                                    {t(appDevelopersTab + "requirments.bullet_point_2")}
                                </li>
                                <li>
                                    {t(appDevelopersTab + "requirments.bullet_point_3")}
                                </li>
                                <li>
                                    {t(appDevelopersTab + "requirments.bullet_point_4")}
                                </li>
                            </ul>

                            <div className='title--black'>{t(appDevelopersTab + "special_requirments.title")}</div>
                            <div className='text_style'>
                                {t(appDevelopersTab + "special_requirments.content")}
                            </div>
                            <ul>
                                <li>
                                    {t(appDevelopersTab + "special_requirments.bullet_point_1")}
                                </li>
                                <li>
                                    {t(appDevelopersTab + "special_requirments.bullet_point_2")}
                                </li>
                                <li>
                                    {t(appDevelopersTab + "special_requirments.bullet_point_3")}
                                </li>
                            </ul>
                        </Tab>
                    </TabBar>
                </div>

                <div className='careers__sidebar'>
                    <JobApplicationForm />
                </div>
            </div>
        </>
    )
}