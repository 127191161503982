import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { ascon_logo_oracle } from "../../../Images/ImageComponents";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

// Custom Components
import Footer from "Common/Components/Footer/Footer";
import DropdownMenu from "./Components/DropdownMenu/DropdownMenu";

// Style
import "./router.css";
const productsList = [
    {
        to: "FinancialSystems",
        name: "financial_systems",
    },
    {
        to: "DistributionSystems",
        name: "distribution_systems",
    },
    {
        to: "HumanResources",
        name: "human_resources",
    },
    {
        to: "FeaturedSystems",
        name: "featured_systems",
    },
];
const partnersPageList = [
    {
        to: "PartnersInfo",
        name: "partners_info",
    },
    {
        to: "PartnersFinder",
        name: "partner_finder",
    },
];
const sectorsPageList = [
    {
        to: "construction",
        name: "construction",
    },
    {
        to: "RealEstate",
        name: "real_estate",
    },
    {
        to: "commercial",
        name: "commercial",
    },
    {
        to: "industry",
        name: "industry",
    },
    {
        to: "carRental",
        name: "car_rental",
    },
    {
        to: "transportation",
        name: "transportation",
    },
    {
        to: "school",
        name: "school",
    },
    {
        to: "farms",
        name: "farms",
    },
    {
        to: "pharmacy",
        name: "pharmacy",
    },
    {
        to: "maintenance",
        name: "maintenance",
    },
];
export default function Router() {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);
    const [productMenuIsOpen, setProductMenuIsOpen] = useState(false);
    const [partnersMenuIsOpen, setPartnersMenuIsOpen] = useState(false);
    const toggleProductMenu = () => setProductMenuIsOpen(!productMenuIsOpen);
    const togglePartnersMenu = () => setPartnersMenuIsOpen(!partnersMenuIsOpen);
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const { t, i18n } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        console.log(windowWidth);
    };

    useEffect(() => {
        const closeMenuOnClick = (e) => {
            // Assuming 'nav-link' is a class applied to your NavLink components
            if (e.target.closest('.navlink')) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', closeMenuOnClick);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener('click', closeMenuOnClick);
        };
    }, []);
    
    useEffect(() => {
        document.body.dir = currentLanguageCode === "ar" ? "rtl" : "ltr";
    }, [currentLanguageCode]);
    console.log(currentLanguageCode);
    console.log("currentLanguageCode");

    return (
        <div>
            <header className="nav">
                <nav className="nav_main-nav">
                    <img src={ascon_logo_oracle} alt="ASCON" />
                    {windowWidth > 1028 ? (
                        <div className="main-nav_navlinks">
                            <NavLink className="navlink" to="/">
                                {t("nav.home")}
                            </NavLink>
                            <DropdownMenu
                                name={t("nav.products")}
                                navList={productsList}
                            />
                            <NavLink className="navlink" to="solutions">
                                {t("nav.solutions")}
                            </NavLink>
                            <NavLink className="navlink" to="careers">
                                {t("nav.careers")}
                            </NavLink>
                            <NavLink className="navlink" to="contact">
                                {t("nav.contact")}
                            </NavLink>
                            <NavLink className="navlink" to="aboutus">
                                {t("nav.about_us")}
                            </NavLink>
                            <DropdownMenu
                                name={t("nav.partners")}
                                navList={partnersPageList}
                            />
                            <DropdownMenu
                                name={t("nav.sectors")}
                                navList={sectorsPageList}
                            />
                            {i18n.language === "en" ? (
                                <button
                                    onClick={() => {
                                        i18n.changeLanguage("ar");
                                    }}
                                    className="navlink">
                                    {t("nav.other_lang")}
                                </button>
                            ) : (
                                <button
                                    onClick={() => {
                                        i18n.changeLanguage("en");
                                    }}
                                    className="navlink">
                                    {t("nav.other_lang")}
                                </button>
                            )}
                        </div>
                    ) : (
                        <div className="collapsed-navbar">
                            <button
                                className="nav_door_button"
                                onClick={toggleOpen}>
                                <div
                                    className={`nav-button ${
                                        isOpen ? "open" : ""
                                    }`}></div>
                            </button>
                            <ul
                                className={`${isOpen ? "open" : ""} 
                            ${
                                i18n.language === "ar"
                                    ? "arabic_collapse-nav"
                                    : "english_collapse-nav"
                            }`}>
                                <li>
                                    <NavLink className="navlink" to="/">
                                        {t("nav.home")}
                                    </NavLink>
                                </li>
                                <li>
                                    <button
                                        className=" collapsed-toggle-menu-button "
                                        onClick={toggleProductMenu}>
                                        Products
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </button>
                                </li>
                                <div
                                    className={`collapsed-toggle-menu-list ${
                                        productMenuIsOpen ? "open" : ""
                                    }`}>
                                    {productsList.map((nav) => (
                                        <li>
                                            <NavLink
                                                to={nav.to}
                                                className=" navlink ">
                                                {t("nav." + nav.name)}
                                            </NavLink>
                                        </li>
                                    ))}
                                </div>
                                <li>
                                    <NavLink className="navlink" to="solutions">
                                        {t("nav.solutions")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="navlink" to="aboutus">
                                        {t("nav.about_us")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="navlink" to="careers">
                                        {t("nav.careers")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="navlink" to="contact">
                                        {t("nav.contact")}
                                    </NavLink>
                                </li>
                                <li>
                                    <button
                                        className=" collapsed-toggle-menu-button "
                                        onClick={togglePartnersMenu}>
                                        {t("nav.partners")}
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </button>
                                </li>
                                <div
                                    className={`collapsed-toggle-menu-list ${
                                        partnersMenuIsOpen ? "open" : ""
                                    }`}>
                                    {partnersPageList.map((nav) => (
                                        <li>
                                            <NavLink
                                                to={nav.to}
                                                className=" navlink ">
                                                {t("nav." + nav.name)}
                                            </NavLink>
                                        </li>
                                    ))}
                                </div>
                                <li>
                                    {i18n.language === "en" ? (
                                        <button
                                            onClick={() => {
                                                i18n.changeLanguage("ar");
                                            }}
                                            className="navlink lang_button">
                                            {t("nav.other_lang")}
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => {
                                                i18n.changeLanguage("en");
                                            }}
                                            className="navlink lang_button">
                                            {t("nav.other_lang")}
                                        </button>
                                    )}
                                </li>
                            </ul>
                        </div>
                    )}
                </nav>
            </header>
            <main>
                <Outlet></Outlet>
                <Footer />
            </main>
        </div>
    );
}
