import React from "react";

// Style
import "./AdvancedFeatures.css";

export default function AdvancedFeatures({ icon, title, text }) {
    return (
        <div className="advanced-features">
            <div className="advanced-features__icon-container">
                <div className="features-icon">{icon}</div>
            </div>
            <div className="features-content">
                <h4>{title}</h4>
                <p>{text}</p>
                <hr/>
            </div>
        </div>
    );
}
