import React from 'react'
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';
/*CSS IMPORTS*/
import "./Benefits.css";
/*IMAGES IMPORTS*/
import { benefits_blue } from 'Images/ImageComponents';
/*ICON IMPORTS*/
import { faMobileScreenButton } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faChartColumn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Benefits() {
    const { t, i18n } = useTranslation();
    const homePageBenefits = "home_page.benefits."
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        console.log(windowWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        
        <div className='benefits_sector'>
        <div className='benefits_sector_img'>
            <div className='benefits_sector_paragraph'>
            {windowWidth < 1200 ? (
                    <div className='paragraph_card'>
                    <FontAwesomeIcon icon={faStar} className='benefits_paragraph_icon' pull="left"/>
                    <div className='benefits-card'>
                    <h1>{t(homePageBenefits +"title_3")}</h1>
                    <p>{t(homePageBenefits +"benefit_3")}</p></div>
                </div>):(null) }
                {windowWidth < 1200 ? (
                    <div className='paragraph_card'>
                    <FontAwesomeIcon icon={faChartColumn} className='benefits_paragraph_icon' pull="left"/>
                    <div className='benefits-card'>
                    <h1>{t(homePageBenefits +"title_6")}</h1>
                    <p>{t(homePageBenefits +"benefit_6")}</p></div>
                    </div>):(null) }</div>
                <img src={benefits_blue} />
                </div>

            <div className='benefits_sector_paragraph'>
                <div className='paragraph_card'>
                        <FontAwesomeIcon icon={faMobileScreenButton} className='benefits_paragraph_icon' pull="left" />
                        <div className='benefits-card'>
                        <h1>{t(homePageBenefits +"title_1")}</h1>
                        <p>{t(homePageBenefits +"benefit_1")}</p></div>
                </div>  
                <div className='paragraph_card'>
                        <FontAwesomeIcon icon={faCheck} className='benefits_paragraph_icon' pull="left"/>
                        <div className='benefits-card'>
                        <h1>{t(homePageBenefits +"title_2")}</h1>
                        <p>{t(homePageBenefits +"benefit_2")}</p></div>
                </div> 
                    {windowWidth > 1200 ? (
                    <div className='paragraph_card'>
                    <FontAwesomeIcon icon={faStar} className='benefits_paragraph_icon' pull="left"/>
                    <div className='benefits-card'>
                    <h1>{t(homePageBenefits +"title_3")}</h1>
                    <p>{t(homePageBenefits +"benefit_3")}</p></div>
                </div>):(null) }
                <div className='paragraph_card'>
                        <FontAwesomeIcon icon={faCloud} className='benefits_paragraph_icon' pull="left"/>
                        <div className='benefits-card'>
                        <h1>{t(homePageBenefits +"title_4")}</h1>
                        <p>{t(homePageBenefits +"benefit_4")}</p></div>
                </div>
                <div className='paragraph_card'>
                        <FontAwesomeIcon icon={faDatabase} className='benefits_paragraph_icon' pull="left"/>
                        <div className='benefits-card'>
                        <h1>{t(homePageBenefits +"title_5")}</h1>
                        <p>{t(homePageBenefits +"benefit_5")}</p></div>
                    </div>
                    {windowWidth > 1200 ? (
                    <div className='paragraph_card'>
                    <FontAwesomeIcon icon={faChartColumn} className='benefits_paragraph_icon' pull="left"/>
                    <div className='benefits-card'>
                    <h1>{t(homePageBenefits +"title_6")}</h1>
                    <p>{t(homePageBenefits +"benefit_6")}</p></div>
                    </div>):(null) }
            </div>
        </div>
    )
}
