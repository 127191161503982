import React from 'react'

// Style
import "./businessSectorsCards.css"

export default function BusinessSectorsCards({index, image, tag}) {
    return (
        <div id={index} className='business-sector_card'>
            <img src={image} alt={index} />
            <h3>{tag}</h3>
        </div>
    )
}
