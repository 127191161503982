import React from 'react'
import { useTranslation, Trans } from "react-i18next";
/*COMPONENTS IMPORTS*/
import Title from 'Common/Components/ArticleTitle/articleTitle';
import SeparationIcon from 'Common/Components/SeparationIcon/separationIcon';
/*CSS IMPORTS*/
import "./IndustrialContent.css";
/*IMAGES IMPORTS*/
import inudustrial from 'Images/Background/inudustrial.png';

export default function IndustrialContent() {
    const { t, i18n } = useTranslation();
    const Industrialpage ="Industrial_page."
    const manufacturingmanagementsystem ="Industrial_page.section.manufacturing_management_system."
    const softwareSection = "Industrial_page.section.software_section."
    const specializedsystems ="Industrial_page.section.specialized_systems."
    return (
    <div className='industrial-Content'>
        <Title>{t(Industrialpage +"title")}</Title>
        <img src={inudustrial} alt='inudustrial image'/>

        <div className='industrial_text'>
            <Trans i18nKey={Industrialpage + "page_content"}>
            <div className='industrial__break' />
            </Trans>

        <SeparationIcon title={t(manufacturingmanagementsystem +"title")}> </SeparationIcon>
        <span>{t(manufacturingmanagementsystem + "content")}</span>
        <ul className='industrial__text'>
            <li> <Trans i18nKey={manufacturingmanagementsystem + "point_1"}>
            <a href={t(manufacturingmanagementsystem + "point_1_url")}></a> </Trans> </li>
            <li>{t(manufacturingmanagementsystem + "point_2")}</li>
            <li>{t(manufacturingmanagementsystem + "point_3")}</li>
            <li>{t(manufacturingmanagementsystem + "point_4")}</li>
            <li>{t(manufacturingmanagementsystem + "point_5")}</li>
            <li>{t(manufacturingmanagementsystem + "point_6")}</li>
            <li>{t(manufacturingmanagementsystem + "point_7")}</li>
            <li>{t(manufacturingmanagementsystem + "point_8")}</li>
            <li>{t(manufacturingmanagementsystem + "point_9")}</li>
            <li>{t(manufacturingmanagementsystem + "point_10")}</li>
            <li>{t(manufacturingmanagementsystem + "point_11")}</li>
            <li>{t(manufacturingmanagementsystem + "point_12")}</li>
            <li>{t(manufacturingmanagementsystem + "point_13")}</li>
            <li>{t(manufacturingmanagementsystem + "point_14")}</li>
            <li>{t(manufacturingmanagementsystem + "point_15")}</li>
            <li>{t(manufacturingmanagementsystem + "point_16")}</li>
            <li>{t(manufacturingmanagementsystem + "point_17")}</li>
            <li>{t(manufacturingmanagementsystem + "point_18")}</li>
            <li>{t(manufacturingmanagementsystem + "point_19")}</li>
            <li>{t(manufacturingmanagementsystem + "point_20")}</li>
            <li>{t(manufacturingmanagementsystem + "point_21")}</li>
            <li>{t(manufacturingmanagementsystem + "point_22")}</li>
            <li>{t(manufacturingmanagementsystem + "point_23")}</li>
    </ul>
    <SeparationIcon title={t(softwareSection +"title")}></SeparationIcon>
    
    <ul className='industrial__text'>
            <li>{t(softwareSection + "point_1")}
                <a href={t(softwareSection + "point_1_url")}> [Details]</a>
            </li>
            <li>{t(softwareSection + "point_2")}
                <a href={t(softwareSection + "point_2_url")}>[Details]</a>
            </li>
            <li>{t(softwareSection + "point_3")}
                <a href={t(softwareSection + "point_3_url")}> [Details]</a>
            </li>
    </ul> 
    <SeparationIcon title={t(specializedsystems +"title")}></SeparationIcon>
    <ul className='industrial__text'>
            <li>{t(specializedsystems + "point_1")}
                <a href={t(specializedsystems + "point_1_url")}> [Details]</a>
            </li>
            <li>{t(specializedsystems + "point_2")}
                <a href={t(specializedsystems + "point_2_url")}>[Details]</a>
            </li>
            <li>{t(specializedsystems + "point_3")}
                <a href={t(specializedsystems + "point_3_url")}> [Details]</a>
            </li>
            <li>{t(specializedsystems + "point_4")}
            <a href={t(specializedsystems + "point_4_url")}> [Details]</a>
            </li>
    </ul> 
        </div>
    </div>
    )
}
