import React from 'react'
/*COMPONENTS IMPORTS*/
import { useTranslation, Trans } from "react-i18next";
import Title from 'Common/Components/ArticleTitle/articleTitle';
import SeparationIcon from 'Common/Components/SeparationIcon/separationIcon';
/*CSS IMPORTS*/
import './CarRentalContent.css';
/*IMAGES IMPORTS*/
import { carRental } from 'Images/ImageComponents';


export default function CarRentalContent() {
    const { t, i18n } = useTranslation();
    const CarRentalpage ="CarRental_page."
    const advantagessection ="CarRental_page.section.advantages_section."
    const softwaresection ="CarRental_page.section.software_section."

    return (
    <div className='car-rental-content'>
        <Title>{t(CarRentalpage +"title")}</Title>
        <img src={carRental}/>
    <div className='car-rental-text'>
        <Trans i18nKey={CarRentalpage + "content"}>
            <a href={t(CarRentalpage+ "content_url_1")}></a>
            <a href={t(CarRentalpage+ "content_url_2")}></a>
        </Trans>
        <span>{t(advantagessection +"content")}</span>

        <ul className='car-rental-text'>
            <li>{t(advantagessection + "point_1")}</li>
            <li>{t(advantagessection + "point_2")}</li>
            <li>{t(advantagessection + "point_3")}</li>
            <li>{t(advantagessection + "point_4")}</li>
            <li>{t(advantagessection + "point_5")}</li>
            <li>{t(advantagessection + "point_6")}</li>
            <li>{t(advantagessection + "point_7")}</li>
            <li>{t(advantagessection + "point_8")}</li>
            <li>{t(advantagessection + "point_9")}</li>
            <li>{t(advantagessection + "point_10")}</li>
            <li>{t(advantagessection + "point_11")}</li>
            <li>{t(advantagessection + "point_12")}</li>
            <li>{t(advantagessection + "point_13")}</li>
            <li>{t(advantagessection + "point_14")}</li>
            <li>{t(advantagessection + "point_15")}</li>
            <li>{t(advantagessection + "point_16")}</li>
            <li>{t(advantagessection + "point_17")}</li>
            <li>{t(advantagessection + "point_18")}</li>
            <li>{t(advantagessection + "point_19")}</li>
            <li>{t(advantagessection + "point_20")}</li>
            <li>{t(advantagessection + "point_21")}</li>
    </ul>
    <SeparationIcon title={t(softwaresection +"title")}></SeparationIcon>
    <ul className='car-rental-text'>
            <li>{t(softwaresection + "point_1")}
                <a href={t(softwaresection + "point_1_url")}> [Details]</a>
            </li>
            <li>{t(softwaresection + "point_2")}
                <a href={t(softwaresection + "point_2_url")}>[Details]</a>
            </li>
            <li>{t(softwaresection + "point_3")}
                <a href={t(softwaresection + "point_3_url")}> [Details]</a>
            </li>
    </ul> 
    </div>
    </div>
    )
}
