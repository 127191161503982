import React from "react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhoneFlip } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import SocialIcons from "../SocialIcons/socialIcons";
import { useTranslation } from "react-i18next";

// Style
import "./footer.css";

export default function Footer() {
    const { t, i18n } = useTranslation();

    return (
        <div className="footer">
            <div className="footer_info">
                <div className="info_colum">
                    <h3>{t("what_we_do")}</h3>
                    <p>{t("what_we_do_pr")}</p>
                </div>
                <div className="info_colum">
                    <h3>{t("contact_info")}</h3>
                    <ul className="contact-info">
                        <li>
                            <FontAwesomeIcon
                                icon={faGlobe}
                                className="contact-into_icon"
                                pull="left"
                            />
                            {t("contact_info_address")}
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={faPhoneFlip}
                                className="contact-into_icon"
                                pull="left"
                            />
                            <strong>{t("contact_info_phone")}:</strong>
                            <ul className="phones">
                                <li>+966-11-4620694</li>
                                <li>+966-11-4612677</li>
                                <li>+966-11-4664643</li>
                            </ul>
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="contact-into_icon"
                                pull="left"
                            />
                            <strong>{t("contact_info_email")}: </strong>
                            ascon@ascon-me.com
                        </li>
                    </ul>
                </div>
                <div className="info_colum">
                    <h3>{t("quick_links")}</h3>
                    <div className="footer_navlinks">
                        <NavLink className="navlink" to="/">
                            {t("nav.home")}
                        </NavLink>
                        <NavLink className="navlink" to="aboutus">
                            {t("nav.about_us")}
                        </NavLink>
                        <NavLink className="navlink" to="careers">
                            {t("nav.careers")}
                        </NavLink>
                        <NavLink className="navlink" to="contact">
                            {t("nav.contact")}
                        </NavLink>
                    </div>
                </div>
                <div className="info_colum">
                    <h3>{t("social_icons")}</h3>
                    <SocialIcons />
                </div>
            </div>
            <div className="footer_published">
                <p>2019 © ASCON / أسكون © 2019 </p>
            </div>
        </div>
    );
}
