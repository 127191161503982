import React , { useEffect } from 'react';
/*COMPONENTS IMPORTS*/
import MaintenanceContent from './components/MaintenanceContent';
import Sidebar from 'Common/Components/Sidebar/sidebar';
/*CSS IMPORTS*/
import './MaintenanceSector.css';
import './IndexResponsive.css'
export default function MaintenanceSector() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Maintenance and Operation | ASCON";
    });
    return (
        <div className='MaintenanceSector'>
            <div className='MaintenanceSector__content'>
            <MaintenanceContent></MaintenanceContent>   
            </div>
            <div className='MaintenanceSector__sidebar'>
            <Sidebar></Sidebar>
            </div>
        </div>
    )
}
