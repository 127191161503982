import React, {useState} from 'react'
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import {  useTranslation } from "react-i18next";

// Style
import "./dropdownMenu.css"

export default function DropdownMenu({name , navList}) {
    const { t , i18n} = useTranslation();
    return (
        <div className='dropdown-container'>
            <div className="navlink " > 
                {name} <FontAwesomeIcon icon={faAngleDown} />
            </div>
            <div className="dropdown-content">
                {navList.map(nav => (
                    <NavLink to={nav.to} className=" dropdown-item ">{t("nav." + nav.name)}</NavLink>))}
            </div>
        </div>
    )
}
