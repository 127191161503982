import React, { useEffect, useRef } from 'react'
/*ICONS IMPORTS*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { faFile } from '@fortawesome/free-solid-svg-icons'
/*CSS IMPORTS*/
import 'Common/Components/JobApplicationForm/jobApplicationForm.css'
import 'Common/Components/JobApplicationForm/jobApplicationFormResponsive.css'

import { useTranslation, Trans } from "react-i18next";
import Cookies from 'js-cookie';


const JobApplicationForm = () => {

  const { t, i18n } = useTranslation();
  const jobAppForm = "components.job_application_form."

  const [formStatus, setFormStatus] = React.useState("")
  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Submit')
    const { name, email, message, phoneNumber, file } = e.target.elements
    let conFom = {
      name: name.value,
      email: email.value,
      message: message.value,
      phoneNumber: phoneNumber.value,
      file: file.value
    }
    /* Log inputs to console*/
    console.log(conFom)
  }

  const myRefName = useRef()
  const myRefEmail = useRef()
  const myRefPhone = useRef()
  const myRefJob = useRef()

  const currentLanguageCode = Cookies.get('i18next') || 'en';


  useEffect(() => {
    setFormStatus(t(jobAppForm + "submit"))
  }, [t])

  //  Chaning floating labels' positions after changing languages

  useEffect(() => {
    if (currentLanguageCode === "en") {
      myRefName.current.style = "left:5%;";
      myRefEmail.current.style = "left:25%;";
      myRefPhone.current.style = "left:25%;";
      myRefJob.current.style = "left:25%;";
    } else {
      myRefName.current.style = "left:80%;";
      myRefEmail.current.style = "left:32%;";
      myRefPhone.current.style = "left:55%;";
      myRefJob.current.style = "left:60%;";
    }
  }, [t])



  return (
    <form onSubmit={onSubmit} className='input__form'>
      <h2>{t(jobAppForm + "header")}</h2>
      <div className='input__area__wrp'>
        <br />
        <input type="text" className="input__area--name" id="name" required />
        <span className="label__floating--name" ref={myRefName} >{t(jobAppForm + "name")}</span>
      </div>

      <table className='input__area__wrp'>
        <tr>
          <th className='th__left'>
            <FontAwesomeIcon icon={faEnvelope} className='input__area__icon' pull="left" />
          </th>
          <th className='th__right'>
            <input type="text" className="input__area" id="email" required />
            <label className='label__floating' ref={myRefEmail}>{t(jobAppForm + "email")}</label>
          </th>
        </tr>
      </table>

      <table className='input__area__wrp'>
        <tr>
          <th className='th__left'>
            <FontAwesomeIcon icon={faPhoneFlip} className='input__area__icon' pull="left" />
          </th>
          <th className='th__right'>
            <div>
              <input type="text" className="input__area" id="phoneNumber" required />
              <label className='label__floating' ref={myRefPhone}>{t(jobAppForm + "phone")}</label>
            </div>
          </th>
        </tr>
      </table>


      <table className='input__area__wrp'>
        <tr>
          <th className='th__left'>
            <FontAwesomeIcon icon={faQuestion} className='input__area__icon' pull="left" />
          </th>
          <th className='th__right'>
            <textarea className='message__area' id="message" required ></textarea>
            <label className='label__floating--textarea' ref={myRefJob}>{t(jobAppForm + "job_title")}</label>
          </th>
        </tr>
      </table>

      <table className='input__area__wrp'>
        <tr>
          <th className='th__left'>
            <FontAwesomeIcon icon={faFile} className='input__area__icon' pull="left" />
          </th>
          <th className='th__right'>
            <div>
              <input type="file" id="actual-btn" className='input__area' required hidden />
              <label for="actual-btn" className='input__area'>{t(jobAppForm + "cv")}</label>
            </div>
          </th>
        </tr>

      </table>

      <div className='button__containter'>
        <button className='button' type="submit">
          {formStatus}
        </button>
      </div>
    </form>
  )
};
export default JobApplicationForm;
