import React, { useEffect } from "react";

// Custom Component
import SocialIcons from "Common/Components/SocialIcons/socialIcons";
import ContactForm from "Common/Components/ContactForm/contactForm";
import CountrySection from "./Sections/CountySection/CountrySection";
import CitySection from "./Sections/CitySection/CitySection";

import { useTranslation, Trans } from "react-i18next";

// Images
import {
    contact_us_intro,
    country_saudi_arabia,
    country_egypt,
    country_sudan,
    country_turkey,
    country_usa,
} from "Images/ImageComponents";

// Style
import "./contact.css";
import "./indexResponsive.css";

export default function Contact() {
    const contactInfoSection = "contact_us_page.contact_info.";
    const getInTouchSection = "contact_us_page.get_in_touch.";

    // Translation Tag
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Contact | ASCON";
    });
    return (
        <div className="contact-page">
            <img src={contact_us_intro} alt="image" />
            <div className="contact-page_container">
                <div className="contact-info">
                    <div className="contact-page_title">
                        <h3>{t(contactInfoSection + "title")}</h3>
                        <div></div>
                    </div>
                    <CountrySection
                        name={t(contactInfoSection + "usa.title")}
                        imageSrc={country_usa}>
                        <CitySection
                            name={t(
                                contactInfoSection +
                                    "usa.branches.newjersey.title"
                            )}
                            phones={[
                                t(
                                    contactInfoSection +
                                        "usa.branches.newjersey.phone_1"
                                ),
                            ]}
                            address={t(
                                contactInfoSection +
                                    "usa.branches.newjersey.address"
                            )}
                            website={t(
                                contactInfoSection +
                                    "usa.branches.newjersey.website"
                            )}
                        />
                        <CitySection
                            name={t(
                                contactInfoSection +
                                    "usa.branches.california.title"
                            )}
                            phones={[
                                t(
                                    contactInfoSection +
                                        "usa.branches.california.phone_1"
                                ),
                            ]}
                            address={t(
                                contactInfoSection +
                                    "usa.branches.california.address"
                            )}
                            website={t(
                                contactInfoSection +
                                    "usa.branches.california.website"
                            )}
                        />
                    </CountrySection>
                    <div className="contact-page_divider"></div>
                    <CountrySection
                        name={t(contactInfoSection + "saudi.title")}
                        imageSrc={country_saudi_arabia}>
                        <CitySection
                            name={t(
                                contactInfoSection +
                                    "saudi.branches.riyadh.title"
                            )}
                            phones={[
                                t(
                                    contactInfoSection +
                                        "saudi.branches.riyadh.phone_1"
                                ),
                                t(
                                    contactInfoSection +
                                        "saudi.branches.riyadh.phone_2"
                                ),
                                t(
                                    contactInfoSection +
                                        "saudi.branches.riyadh.phone_3"
                                ),
                            ]}
                            address={t(
                                contactInfoSection +
                                    "saudi.branches.riyadh.address"
                            )}
                            website={t(
                                contactInfoSection +
                                    "saudi.branches.riyadh.website"
                            )}
                        />
                        <CitySection
                            name={t(
                                contactInfoSection +
                                    "saudi.branches.jeddah.title"
                            )}
                            phones={[
                                t(
                                    contactInfoSection +
                                        "saudi.branches.jeddah.phone_1"
                                ),
                                t(
                                    contactInfoSection +
                                        "saudi.branches.jeddah.phone_2"
                                ),
                            ]}
                            address={t(
                                contactInfoSection +
                                    "saudi.branches.jeddah.address"
                            )}
                            website={t(
                                contactInfoSection +
                                    "saudi.branches.riyadh.website"
                            )}
                        />
                        <CitySection
                            name={t(
                                contactInfoSection +
                                    "saudi.branches.alkhobar.title"
                            )}
                            phones={[
                                t(
                                    contactInfoSection +
                                        "saudi.branches.alkhobar.phone_1"
                                ),
                                t(
                                    contactInfoSection +
                                        "saudi.branches.alkhobar.phone_2"
                                ),
                            ]}
                            address={t(
                                contactInfoSection +
                                    "saudi.branches.alkhobar.address"
                            )}
                            website={t(
                                contactInfoSection +
                                    "saudi.branches.alkhobar.website"
                            )}
                        />
                    </CountrySection>
                    <div className="contact-page_divider"></div>
                    
                    <CountrySection
                        name={t(contactInfoSection + "egypt.title")}
                        imageSrc={country_egypt}>
                        <CitySection
                            name={t(
                                contactInfoSection +
                                    "egypt.branches.alexandria.title"
                            )}
                            phones={[
                                t(
                                    contactInfoSection +
                                        "egypt.branches.alexandria.phone_1"
                                ),
                                t(
                                    contactInfoSection +
                                        "egypt.branches.alexandria.phone_2"
                                ),
                                t(
                                    contactInfoSection +
                                        "egypt.branches.alexandria.phone_3"
                                ),
                            ]}
                            address={t(
                                contactInfoSection +
                                    "egypt.branches.alexandria.address"
                            )}
                            website={t(
                                contactInfoSection +
                                    "egypt.branches.alexandria.website"
                            )}
                        />
                        <CitySection
                            name={t(
                                contactInfoSection +
                                    "egypt.branches.cairo.title"
                            )}
                            phones={[
                                t(
                                    contactInfoSection +
                                        "egypt.branches.cairo.phone_1"
                                ),
                            ]}
                            address={t(
                                contactInfoSection +
                                    "egypt.branches.cairo.address"
                            )}
                            website={t(
                                contactInfoSection +
                                    "egypt.branches.cairo.website"
                            )}
                        />
                    </CountrySection>
                    <div className="contact-page_divider"></div>
                    <CountrySection
                        name={t(contactInfoSection + "sudan.title")}
                        imageSrc={country_sudan}>
                        <CitySection
                            name={t(
                                contactInfoSection +
                                    "sudan.branches.alkhartoum.title"
                            )}
                            phones={[
                                t(
                                    contactInfoSection +
                                        "sudan.branches.alkhartoum.phone_1"
                                ),
                            ]}
                            address={t(
                                contactInfoSection +
                                    "sudan.branches.alkhartoum.address"
                            )}
                            website={t(
                                contactInfoSection +
                                    "sudan.branches.alkhartoum.website"
                            )}
                        />
                    </CountrySection>
                    <div className="contact-page_divider"></div>
                    <CountrySection
                        name={t(contactInfoSection + "turkey.title")}
                        imageSrc={country_turkey}>
                        <CitySection
                            name={t(
                                contactInfoSection +
                                    "turkey.branches.istanbul.title"
                            )}
                            phones={[
                                t(
                                    contactInfoSection +
                                        "turkey.branches.istanbul.phone_1"
                                ),
                                t(
                                    contactInfoSection +
                                        "turkey.branches.istanbul.phone_2"
                                ),
                            ]}
                            address={t(
                                contactInfoSection +
                                    "turkey.branches.istanbul.address"
                            )}
                            website={t(
                                contactInfoSection +
                                    "turkey.branches.istanbul.website"
                            )}
                        />
                    </CountrySection>
                </div>
                <div className="git-in-touch">
                    <div className="contact-page_title">
                        <h3>{t(getInTouchSection + "title")}</h3>
                        <div></div>
                    </div>
                    <p>{t(getInTouchSection + "content")}</p>
                    <SocialIcons />
                    <div className="contact-page_divider"></div>
                    <ContactForm />
                </div>
            </div>
        </div>
    );
}
