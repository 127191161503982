import React from 'react'
/*COMPONENTS IMPORTS*/
import ContactForm from 'Common/Components/ContactForm/contactForm';
import SocialIcons from 'Common/Components/SocialIcons/socialIcons';
import ScrollableSidebar from 'Common/Components/Sidebar/scrollableSidebar';
/*CSS IMPORTS*/
import 'Common/Components/Sidebar/sidebar.css'

import { useTranslation } from "react-i18next";

const Sidebar = () => {

  const { t, i18n } = useTranslation();
  const sidebar = "components.sidebar."

  return (
    <>
      <div className='sidebar'>
        <ScrollableSidebar>
          <h3 className="sidebar__title">{t(sidebar + "first_header.title")}</h3>
          <h2 className="sidebar__content__text">{t(sidebar + "first_header.content")}</h2>
          <h3 className="sidebar__title">{t(sidebar + "second_header")}</h3>
          <SocialIcons></SocialIcons>
          <h3 className="sidebar__title">{t(sidebar + "third_header")}</h3>
          <ContactForm></ContactForm>
        </ScrollableSidebar>
      </div>
    </>
  )
}
export default Sidebar