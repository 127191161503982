import React from 'react'
import "Common/Components/TabBar/tabBar.css"
import "Common/Components/TabBar/tabBarResponsive.css"


export default function TabBar(props) {
    return (
        <div className="tabs">
            {props.children}
        </div>
    )
}
