import React from 'react'
/*COMPONENTS IMPORTS*/
import { useTranslation, Trans } from "react-i18next";
import Title from 'Common/Components/ArticleTitle/articleTitle';
import SeparationIcon from 'Common/Components/SeparationIcon/separationIcon';
/*CSS IMPORTS*/
import './FarmContent.css';

/*IMAGES IMPORTS*/
import { farmSector } from 'Images/ImageComponents';

export default function FarmContent() {
    const { t, i18n } = useTranslation();
    const Farmpage = "Farm_page."
    const advantagessection ="Farm_page.section.advantages_section."
    const softwaresection ="Farm_page.section.software_section."
    return (
        <div className='farm-content'>
            <Title>{t(Farmpage +"title")}</Title>
            <img src={farmSector} />
                <div className='farm-text'>
                <Trans i18nKey={Farmpage+ "content"}>
                <a href={t(Farmpage+ "content_url")}></a></Trans>
                    <ul className='farm-taxt'>
                        <li>{t(advantagessection + "point_1")}</li>
                        <li>{t(advantagessection + "point_2")}</li>
                        <li>{t(advantagessection + "point_3")}</li>
                        <li> <Trans i18nKey={advantagessection + "point_4"}>
                        <a href={t(advantagessection + "point_4_url")}></a> </Trans> </li>
                        <li>{t(advantagessection + "point_5")}</li>
                        <li>{t(advantagessection + "point_6")}</li>
                        <li>{t(advantagessection + "point_7")}</li>
                        <li>{t(advantagessection + "point_8")}</li>
                        <li>{t(advantagessection + "point_9")}</li>
                        <li>{t(advantagessection + "point_10")}</li>
                        <li>{t(advantagessection + "point_11")}</li>
                        <li>{t(advantagessection + "point_12")}</li>
                        </ul>
                        <SeparationIcon title={t(softwaresection +"title")}></SeparationIcon>
                    <ul className='transportation-taxt'>
                        <li>{t(softwaresection + "point_1")}
                        <a href={t(softwaresection + "point_1_url")}> [Details]</a></li>
                        <li>{t(softwaresection + "point_2")}
                        <a href={t(softwaresection + "point_2_url")}>[Details]</a></li>
                        <li>{t(softwaresection + "point_3")}
                        <a href={t(softwaresection + "point_3_url")}> [Details]</a></li>
                        </ul> 
                </div>

        </div>
    )
}
