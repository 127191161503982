import React , { useEffect } from 'react';
/*COMPONENTS IMPORTS*/
import PharmaciesContent from './components/PharmaciesContent';
import Sidebar from 'Common/Components/Sidebar/sidebar';
/*CSS IMPORTS*/
import './PharmaciesSector.css'
import './IndexResponsive.css'
export default function PharmaciesSector() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Pharmacies and Pharmaceutical trade | ASCON";
    });
    return (
        <div className='PharmaciesSector'>
            <div className='PharmaciesSector__content'>
                <PharmaciesContent></PharmaciesContent>
            </div>
            <div className='PharmaciesSector__sidebar'>
                <Sidebar></Sidebar>
            </div>
        </div>
    )
}
