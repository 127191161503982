import React from 'react'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Style
import "./citySection.css"

export default function CitySection({ name, phones, address, website }) {
    return (
        <div className='city-section'>
            <span>{name}</span>
            {/* <h2>{name}</h2> */}
            <ul className='contact-info'>
                {address && <li >
                    <FontAwesomeIcon icon={faGlobe} className='contact-into_icon' pull="left" />
                    {address}
                </li>}
                {phones && <li >
                    <FontAwesomeIcon icon={faPhoneFlip} className='contact-into_icon' pull="left" />
                    <ul className='phones'>
                        {phones.map(phone => (<li >{phone}</li>))}
                    </ul>
                </li>}
                {website && <li >
                    <FontAwesomeIcon icon={faEnvelope} className='contact-into_icon' pull="left" />
                    {website}
                </li>}
            </ul>
        </div>
    )
}
