import React from 'react'

export default function Tab(props) {
    return (
        <>
            <input class="input" name="tabs" type="radio" id={props.id} defaultChecked={props.defaultTab} />
            <label class="label" for={props.id}>{props.title}</label>
            <div class="panel">
                {props.children}
            </div>
        </>

    );
}
