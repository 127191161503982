import React , { useEffect } from 'react';
/*COMPONENTS IMPORTS*/
import SchoolContent from './components/SchoolContent';
import Sidebar from 'Common/Components/Sidebar/sidebar';
/*CSS IMPORTS*/
import './SchoolPage.css';
import './IndexResponsive.css'

export default function SchoolPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Schools and educational institutions sector | ASCON";
    });
    return (
        <div className='SchoolPage'>
            <div className='SchoolPage__content'>
                <SchoolContent></SchoolContent>
            </div>
            <div className='SchoolPage__sidebar'>
                <Sidebar></Sidebar>
            </div>
        </div>
    )
}
