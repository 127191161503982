import React , { useEffect } from 'react';
/*COMPONENTS IMPORTS*/
import Sidebar from 'Common/Components/Sidebar/sidebar'
import Content from './Components/content/content';
/*CSS IMPORTS*/
import "./RealState.css"
import "./IndexResponsive.css"

export default function RealState() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Real Estate | ASCON";
    });
    return (
        <div className='real-state'>
            
            <div className='real-state__content'>
                <Content></Content>
            </div>
            <div className='real-state__sidebar'>
                <Sidebar></Sidebar>
            </div>
        </div>
    )
}
