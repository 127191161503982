import React, {useEffect} from 'react'

// Custom Components
import SideNav from 'Common/Components/SideNav/SideNav';
import ContactForm from 'Common/Components/ContactForm/contactForm';
import SocialIcons from 'Common/Components/SocialIcons/socialIcons';

// Image & Context
import { financial_systems_intro } from 'Images/ImageComponents';
import  navigationItems  from "./Context/items"

// Styles
import './financialSystems.css'; 



export default function FinancialSystems ()  {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Financial Systems | ASCON";
    });
    return (
        <div className="financial-container">
            <img src={financial_systems_intro} alt='financial image' />
            <div className='financial_content'>
                <SideNav navigationItems={navigationItems} page={"financial_systems"}/>
                <div className='content_right-side'>
                    <ContactForm />
                    <div className='contact-page_divider ' />
                    <SocialIcons />
                    <div className='contact-page_divider ' />
                </div>
            </div>
        </div>
    );
};

