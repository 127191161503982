import React from 'react'
import { useTranslation, Trans } from "react-i18next";
/*COMPONENTS IMPORTS*/
import Title from 'Common/Components/ArticleTitle/articleTitle';
import SeparationIcon from 'Common/Components/SeparationIcon/separationIcon';
/*CSS IMPORTS*/
import'./content.css'
/*IMAGES IMPORTS*/
import realEstateImage from 'Images/Background/realEstateImage.png'

function Content() {
    const { t, i18n } = useTranslation();
    const realstatePage = "realstate_page."
    const advantagesSection = "realstate_page.section.advantages_section."
    const softwareSection = "realstate_page.section.software_section."
  return (
    <div className='real-state__content'>
        <Title>{t(realstatePage +"title")}</Title>
        <img src={realEstateImage} alt="Real Estate Image" />
        
        <div className='real-state__text'>
        <Trans i18nKey={realstatePage + "content"}>
        <div className='real-state__break' />
        </Trans>
        
    <SeparationIcon title={t(advantagesSection +"title")}></SeparationIcon>
    
    <ul className='real-state__text'>
            <li>{t(advantagesSection + "point_1")}</li>
            <li>{t(advantagesSection + "point_2")}</li>
            <li>{t(advantagesSection + "point_3")}</li>
            <li>{t(advantagesSection + "point_4")}</li>
            <li>{t(advantagesSection + "point_5")}</li>
            <li>{t(advantagesSection + "point_6")}</li>
            <li>{t(advantagesSection + "point_7")}</li>
            <li>{t(advantagesSection + "point_8")}</li>
            <li>{t(advantagesSection + "point_9")}</li>
            <li>{t(advantagesSection + "point_10")}</li>
            <li>{t(advantagesSection+ "point_11")}</li>
            <li>{t(advantagesSection + "point_12")}</li>
            <li>{t(advantagesSection + "point_13")}</li>
            <li>{t(advantagesSection + "point_14")}</li>
            <li>{t(advantagesSection + "point_15")}</li>
            <li>{t(advantagesSection + "point_16")}</li>
    </ul>
    <SeparationIcon title={t(softwareSection +"title")}></SeparationIcon>
    
    <ul className='real-state__text'>
              <li>{t(softwareSection + "point_1")}
                <a href={t(softwareSection + "point_1_url")}> [Details]</a>
              </li>
              <li>{t(softwareSection + "point_2")}
                <a href={t(softwareSection + "point_2_url")}>[Details]</a>
              </li>
              <li>{t(softwareSection + "point_3")}
                <a href={t(softwareSection + "point_3_url")}> [Details]</a>
              </li>
              
    </ul>
    </div>
    </div>
  )
}
export default Content;