import AboutUsCard from "../../Components/AboutUsCard/AboutUsCards";
import { about_us_slide2 } from "Images/ImageComponents";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb ,faUserTie} from "@fortawesome/free-solid-svg-icons";
import "./Activities.css";

export default function Activities() {
    const { t } = useTranslation();
    return (
        <div className="activity-container">
            <div>
                <img className="activity-slide" src={about_us_slide2} title="slide"/>
                <h2 className="activity-title">{t("about_us.activeTitle1")}</h2>
            </div>
            <div className="cards">
                <AboutUsCard
                    icn={
                        <FontAwesomeIcon
                            icon={faLightbulb}
                            className="aboutUsCardIcon"
                        />
                    }
                    title={t("about_us.activeTitle2")}
                    text={t("about_us.activeText1")}
                />
                <AboutUsCard
                    icn={
                        <FontAwesomeIcon
                            icon={faUserTie}
                            className="aboutUsCardIcon"
                        />
                    }
                    title={t("about_us.activeTitle3")}
                    text={t("about_us.activeText2")}
                />
            </div>
        </div>
    );
}
