import React from 'react'
/*COMPONENTS IMPORTS*/
import { useTranslation, Trans } from "react-i18next";
import Title from 'Common/Components/ArticleTitle/articleTitle';
import SeparationIcon from 'Common/Components/SeparationIcon/separationIcon';
/*CSS IMPORTS*/
import './SchoolContent.css';
/*IMAGES IMPORTS*/
import { schools } from 'Images/ImageComponents';

export default function SchoolContent() {
    const { t, i18n } = useTranslation();
    const Schoolpage = "School_page."
    const advantagessection ="School_page.section.advantages_section."
    const softwaresection ="School_page.section.software_section."
    return (
        <div className='school-content'>
            <Title>{t(Schoolpage +"title")}</Title>
            <img src={schools}/>
                <div className='school-text'>
                <Trans i18nKey={Schoolpage + "content"}></Trans>
                    <span>{t(advantagessection +"content")}</span>
                    <ul className='transportation-taxt'>
                        <li>{t(advantagessection + "point_1")}</li>
                        <li>{t(advantagessection + "point_2")}</li>
                        <li>{t(advantagessection + "point_3")}</li>
                        <li>{t(advantagessection + "point_4")}</li>
                        <li>{t(advantagessection + "point_5")}</li>
                        <li>{t(advantagessection + "point_6")}</li>
                        <li>{t(advantagessection + "point_7")}</li>
                        <li>{t(advantagessection + "point_8")}</li>
                        <li>{t(advantagessection + "point_9")}</li>
                        <li>{t(advantagessection + "point_10")}</li>
                        <li>{t(advantagessection + "point_11")}</li>
                        </ul>
                        <SeparationIcon title={t(softwaresection +"title")}></SeparationIcon>
                    <ul className='transportation-taxt'>
                        <li>{t(softwaresection + "point_1")}
                        <a href={t(softwaresection + "point_1_url")}> [Details]</a></li>
                        <li>{t(softwaresection + "point_2")}
                        <a href={t(softwaresection + "point_2_url")}>[Details]</a></li>
                        <li>{t(softwaresection + "point_3")}
                        <a href={t(softwaresection + "point_3_url")}> [Details]</a></li>
                        </ul> 
                </div>
        </div>
    )
}
