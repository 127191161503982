import React , { useEffect } from 'react';
/*COMPONENTS IMPORTS*/
import CarRentalContent from './Components/CarRentalContent'
import Sidebar from 'Common/Components/Sidebar/sidebar'
/*CSS IMPORTS*/
import './CarRental.css'
import './IndexResponsive.css'

export default function CarRental() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Car Rental | ASCON";
    });
    return (
    <div className='CarRental'>

        <div className='CarRental__Content'>
            <CarRentalContent></CarRentalContent>
        </div>

        <div className='CarRental__Sidebar'>
            <Sidebar></Sidebar>
        </div>
    </div>
    )
}
