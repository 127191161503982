import React from 'react'
/*COMPONENTS IMPORTS*/
import Title from 'Common/Components/ArticleTitle/articleTitle'
import SeparationIcon from 'Common/Components/SeparationIcon/separationIcon'
/*CSS IMPORTS*/
import 'Pages/Construction/Components/Article/article.css'
/*IMAGES IMPORTS*/
import constructionImg from 'Images/Backgrounds/portfolio_construction_whiteFrame.png'

// Translation Tag
import { useTranslation, Trans } from "react-i18next";

const Article = () => {
  const { t, i18n } = useTranslation();
  const constructionPage = "construction_page."
  const tendersSection = "construction_page.sections.tenders_section."
  const trafficSection = "construction_page.sections.traffic_section."
  const laborSection = "construction_page.sections.labor_section."
  const softwareSection = "construction_page.sections.softwate_section."
  return (
    <>
      <div className="content">
        <Title>{t(constructionPage + "title")}</Title>
        <img
          src={constructionImg} alt="contruction_image"
          className="article__image"></img>

        <div className="text__font">
          <Trans i18nKey={constructionPage + "introduction"}>
            <a href={t(constructionPage + "introduction_url")}></a>
            <div className='break' />
          </Trans>

          <SeparationIcon title={t(tendersSection + "title")}></SeparationIcon>
          <ul className="text__font" >
            <li>{t(tendersSection + "bullet_point_1")}</li>
            <li>{t(tendersSection + "bullet_point_2")}</li>
            <li>{t(tendersSection + "bullet_point_3")}</li>
            <li>{t(tendersSection + "bullet_point_4")}</li>
            <li>{t(tendersSection + "bullet_point_5")}</li>
            <li>{t(tendersSection + "bullet_point_6")}</li>
            <li>{t(tendersSection + "bullet_point_7")}</li>
            <li>{t(tendersSection + "bullet_point_8")}</li>
            <li>{t(tendersSection + "bullet_point_9")}</li>
            <li>{t(tendersSection + "bullet_point_10")}</li>
            <li>{t(tendersSection + "bullet_point_11")}</li>
            <li>{t(tendersSection + "bullet_point_12")}</li>
            <li>{t(tendersSection + "bullet_point_13")}</li>
            <li>{t(tendersSection + "bullet_point_14")}</li>
            <li>{t(tendersSection + "bullet_point_15")}</li>
            <li>{t(tendersSection + "bullet_point_16")}</li>
            <li>{t(tendersSection + "bullet_point_17")}</li>
            <li>{t(tendersSection + "bullet_point_18")}</li>
            <li>
              <Trans i18nKey={tendersSection + "bullet_point_19"}>
                <a href={t(tendersSection + "bullet_point_19_url")}></a>
              </Trans>
            </li>
          </ul>

          <SeparationIcon title={t(trafficSection + "title")}></SeparationIcon>
          <ul className="text__font" >
            <li>{t(trafficSection + "bullet_point_1")}</li>
            <li>{t(trafficSection + "bullet_point_2")}</li>
            <li>{t(trafficSection + "bullet_point_3")}</li>
            <li>{t(trafficSection + "bullet_point_4")}</li>
            <li>{t(trafficSection + "bullet_point_5")}</li>
            <li>{t(trafficSection + "bullet_point_6")}</li>
            <li>
              <Trans i18nKey={trafficSection + "bullet_point_7"}>
                <a href={t(trafficSection + "bullet_point_7_url")}></a>
              </Trans>
            </li>
            <li>{t(trafficSection + "bullet_point_8")}</li>
            <li>{t(trafficSection + "bullet_point_9")}</li>
            <li>{t(trafficSection + "bullet_point_10")}</li>
            <li>{t(trafficSection + "bullet_point_11")}</li>
            <li>{t(trafficSection + "bullet_point_12")}</li>
          </ul>

          <SeparationIcon title={t(laborSection + "title")}></SeparationIcon>
          <ul className="text__font" >
            <li>{t(laborSection + "bullet_point_1")}</li>
            <li>{t(laborSection + "bullet_point_2")}</li>
            <li>{t(laborSection + "bullet_point_3")}</li>
            <li>{t(laborSection + "bullet_point_4")}</li>
            <li>{t(laborSection + "bullet_point_5")}</li>
            <li>{t(laborSection + "bullet_point_6")}</li>
            <li>{t(laborSection + "bullet_point_7")}</li>
          </ul>

          <SeparationIcon title={t(softwareSection + "title")}></SeparationIcon>
          <ul className="text__font">
            <li>
              <Trans i18nKey={softwareSection + "financial_regulations"}>
                <a href={t(softwareSection + "financial_regulations_url")}></a>
              </Trans>
            </li>
            <li>
              <Trans i18nKey={softwareSection + "hr_systems"}>
                <a href={t(softwareSection + "hr_systems_url")}></a>
              </Trans>
            </li>
            <li>
              <Trans i18nKey={softwareSection + "dist_systems"}>
                <a href={t(softwareSection + "dist_systems_url")}></a>
              </Trans>
            </li>
          </ul>

          <span className="text__bold">{t(softwareSection + "specialized_systems")}</span>
          <ul className="text__font">
            <li>
              <Trans i18nKey={softwareSection + "cost_system"}>
                <a href={t(softwareSection + "cost_system_url")}></a>
              </Trans>
            </li>
            <li>
              <Trans i18nKey={softwareSection + "equipment_system"}>
                <a href={t(softwareSection + "equipment_system_url")}></a>
              </Trans>
            </li>
            <li>
              <Trans i18nKey={softwareSection + "maintenance_system"}>
                <a href={t(softwareSection + "maintenance_system_url")}></a>
              </Trans>
            </li>
            <li>
              <Trans i18nKey={softwareSection + "insurance_system"}>
                <a href={t(softwareSection + "insurance_system_url")}></a>
              </Trans>
            </li>
          </ul>
        </div>
      </div>
    </>
  )

}
export default Article