import React from "react";

import { useTranslation } from "react-i18next";

// Images
import {
    ascon_devices,
    logo_ebrd,
    logo_imc,
    logo_oracle,
} from "Images/ImageComponents";

// Style
import "./partnersSection.css";

//  Translation tag
const homePagePartners = "home_page.partners."

export default function PartnersSection() {
    const { t, i18n } = useTranslation();
    return (
        <div className="partners-section">
            <div className="partners-section_paragraph">
                <img src={ascon_devices} alt="ascon devices" />
                <div className="paragraph">
                    <p>
                        {t(homePagePartners + "paragraph_1")}
                    </p>
                    <p>
                        {t(homePagePartners + "paragraph_2")}
                    </p>
                </div>
            </div>
            <div className="partners-section_logos">
                <img src={logo_ebrd} alt="European Bank" />
                <img src={logo_oracle} alt="ORACLE Gold Partner" />
                <img src={logo_imc} alt="IMC Partner" />
            </div>
        </div>
    );
}
