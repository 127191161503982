import React from "react";

// Style
import "./AboutUsCards.css";

export default function AboutUsCard({ icn, title, text }) {
    return (
        <div className="aboutus-card">
            <div className="icn">{icn}</div>
            <div className="card-content">
                <h2>{title} </h2>
                <p>{text} </p>
            </div>
        </div>
    );
}
