import React , { useEffect } from 'react';
/*COMPONENTS IMPORTS*/
import TransportationContent from './components/TransportationContent';
import Sidebar from 'Common/Components/Sidebar/sidebar';
/*CSS IMPORTS*/
import './Transportation.css';
import './IndexResponsive.css';

export default function Transportation() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Transportation | ASCON";
    });
    return (
        <div className='Transportation'>
            <div className='Transportation__content'>
                <TransportationContent></TransportationContent>
            </div>
            <div className='Transportation__sidebar'>
                <Sidebar></Sidebar>
            </div>

        </div>
    )
}
