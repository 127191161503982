import React from 'react';
import Loading from 'react-loading';

// Style
import "./loadingCircle.css"

const LoadingComponent = ({color, height, width}) => {
    return (
        <div className="loading-container">
            <Loading type="spin" color={color || "#333"} height={ height || 50} width={width || 50} />
        </div>
    );
};

export default LoadingComponent;
