import React, {useEffect} from 'react'

import TabBar from 'Common/Components/TabBar/TabBar'
import Tab from 'Common/Components/Tab/Tab'
import Title from 'Pages/Careers/Components/Title/Title'
import JobApplicationForm from 'Common/Components/JobApplicationForm/JobApplicationForm'

import 'Pages/PartnersInfo/index.css'
import 'Pages/PartnersInfo/indexResponsive.css'

import { useTranslation } from "react-i18next";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function PartnersInfo() {
    const { t, i18n } = useTranslation();
    const PartnersOverview = "partners_overview."

    
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Partners Info | ASCON";
    });
    return (
        <>
            <div className='partners-info__title'>{t(PartnersOverview + "title")}</div>
            <div className='partners-info__container'>
                <div className='partners-info__body'>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }} aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell>{t(PartnersOverview + "tableHead.level")} </StyledTableCell>
                                <StyledTableCell >{t(PartnersOverview + "tableHead.jurisdiction")}</StyledTableCell>
                                <StyledTableCell >{t(PartnersOverview + "tableHead.source_code")}</StyledTableCell>
                                <StyledTableCell >{t(PartnersOverview + "tableHead.certified_consultant")}</StyledTableCell>
                                <StyledTableCell align="">{t(PartnersOverview + "tableHead.certified_engineers")}</StyledTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {t(`${PartnersOverview}tableBody`, { returnObjects: true }).map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.tier}
                                        </StyledTableCell>
                                        <StyledTableCell align="">{row.jurisdiction}</StyledTableCell>
                                        <StyledTableCell align="">{row.code}</StyledTableCell>
                                        <StyledTableCell align="">{row.consultant}</StyledTableCell>
                                        <StyledTableCell align="">{row.engineers}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <table className="table_description">
                    <h1>{t(PartnersOverview + "platinum_partner_description")}</h1>
                        <tbody>
                        {t(`${PartnersOverview}columnDefinitions`, { returnObjects: true }).map((row, index) => (
                            <tr key={index}>
                                <td className='first_column'>{row.column1}</td>
                                <td>{row.column2}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className='dashed_line'></div>
                    <table className="table_description">
                        <tbody>
                        {t(`${PartnersOverview}starDefinitions`, { returnObjects: true }).map((row, index) => (
                            <tr key={index}>
                                <td className='first_column'>{row.column1}</td>
                                <td>{row.column2}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
