import React , { useEffect } from 'react';
import IndustrialContent from './components/IndustrialContent';
import Sidebar from 'Common/Components/Sidebar/sidebar';
import "./IndexResponsive.css"
import "./Industrial.css"

export default function Industrial() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Industrial | ASCON";
    });

    return (
    <div className='Industrial'>
        <div className='Industrial__Content'>
            <IndustrialContent></IndustrialContent>
        </div>
        <div className='Industrial__sidebar'>
            <Sidebar></Sidebar>
        </div>
    </div>
    )
}

