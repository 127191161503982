import React, { useRef, useEffect, useState } from 'react';

const ScrollableSidebar = (props) => {
  /*
  Scrollable Sidebar.
  */
  const sidebarRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const sidebar = sidebarRef.current;
    
    if (sidebar) {
      const containerRect = sidebar.parentElement.getBoundingClientRect();
      const footerRect = document.querySelector('.footer').getBoundingClientRect();
      if (containerRect.bottom <= (window.innerHeight) && 
          100 <= (footerRect.top - (window.innerHeight*0.75))) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sidebarStyle = {
    width: isSticky ? sidebarRef.current.offsetWidth + 'px' : 'auto',
  };

  const classStyle = `sidebar ${isSticky ? 'sticky' : ''}`;
  return (
    <div className={classStyle} style={sidebarStyle} ref={sidebarRef}>
      {props.children}
    </div>
  );
};

export default ScrollableSidebar
