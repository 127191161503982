import { Link } from "react-router-dom";
import AdvancedFeatures from "../../Components/AdvancedFeatures/AdvancedFeatures";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHardDrive ,faCloud ,faArrowsRotate  } from "@fortawesome/free-solid-svg-icons";
import { about_us_features } from "Images/ImageComponents";
import "./Features.css"
export default function Features() {
    const { t } = useTranslation();
    return (
        <div className="advanced-section">
            <div className="menu-container">
                <h2>{t("about_us.advance")}</h2>
                <AdvancedFeatures
                    icon={
                        <FontAwesomeIcon
                            icon={faHardDrive}
                        />
                    }
                    title={t("about_us.featuresTitle1")}
                    text={t("about_us.featuresText1")}
                />
                <AdvancedFeatures
                    icon={
                        <FontAwesomeIcon
                            icon={faCloud}
                        />
                    }
                    title={t("about_us.featuresTitle2")}
                    text={t("about_us.featuresText2")}
                />
                <AdvancedFeatures
                    icon={
                        <FontAwesomeIcon
                            icon={faArrowsRotate}
                        />
                    }
                    title={t("about_us.featuresTitle3")}
                    text={t("about_us.featuresText3")}
                />
                <Link to="/contact">
                    <button >{t("home_page.contact_us")}</button>
                </Link>
            </div>
            <img src={about_us_features} title="image"/>
        </div>
    );
}
