import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, 
            faClock,
            faCopy,
            faTableCells,
            faAddressBook,
            faList} from '@fortawesome/free-solid-svg-icons';


const navigationItems = [
    { key: "human_intro", 
        icon:<FontAwesomeIcon icon={faList} />,
    },
    { key: "personnel", 
        icon:<FontAwesomeIcon icon={faUsers} />,
    },
    { key: 'attendance_system', 
        icon:<FontAwesomeIcon icon={faClock} />,
    },
    { key: 'self_service', 
        icon:<FontAwesomeIcon icon={faCopy} />,
    },
    { key: 'wages_salaries', 
        icon:<FontAwesomeIcon icon={faTableCells} />,
    },
    { key: 'employees_training', 
        icon:<FontAwesomeIcon icon={faAddressBook} />,
    },
];


export default navigationItems;