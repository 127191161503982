import React, { useState, useEffect } from 'react';
import {  useTranslation } from "react-i18next";

import './sideNav.css'; 

export default function SideNav ({navigationItems, page})  {
    const [activeIndex, setActiveIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { t , i18n} = useTranslation();

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        console.log(windowWidth)
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    useEffect(() => {
        const contentDiv = document.querySelector('.content-active');
        if (contentDiv) {
        contentDiv.style.opacity = 0;
        setTimeout(() => {
            contentDiv.style.opacity = 1;
        }, 10);
        }
    }, [activeIndex]); 

    return (
        <div className="sidenav-container">
            <div className="sidenav-container_navigation">
                {navigationItems.map((item, index) => (
                <button key={item.key}
                        className={`nav-item ${index === activeIndex ? 'active' : ''}`}
                        onClick={() => setActiveIndex(index)}>
                    <div className='sidenav-content-right'>
                        <div className='content-content'>
                            <div className='content-content_icon'>{item.icon}</div>
                            {t(`${page}.${item.key}.nav_title`)}
                        </div>
                        {windowWidth <= 920 &&
                            <div className="sidenav-container_content">
                                <div className={`content-item do-not-display ${activeIndex === index && "content-active"}`}>
                                    <h1 className='content-item_title'>{t(`${page}.${navigationItems[index].key}.title`)} </h1>
                                    <h3 className='content-item_subtitle'>{t(`${page}.${navigationItems[index].key}.subtitle`)} </h3>
                                    <ul>
                                        {t(`${page}.${navigationItems[index].key}.body_type`) === "array" &&
                                        t(`${page}.${navigationItems[index].key}.body`, { returnObjects: true }).map(list => (
                                            <li>{list}</li>
                                        ))}
                                    </ul>
                                        {t(`${page}.${navigationItems[index].key}.body_type`) === "text" &&
                                        t(`${page}.${navigationItems[index].key}.body`)}
                                </div>
                            </div>
                        }
                    </div>
                </button>
                ))}
            </div>
            {windowWidth > 920 &&
                <div className="sidenav-container_content">
                    <div className={`content-item content-active`}>
                        <h1 className='content-item_title'>{t(`${page}.${navigationItems[activeIndex].key}.title`)} </h1>
                        <h3 className='content-item_subtitle'>{t(`${page}.${navigationItems[activeIndex].key}.subtitle`)} </h3>
                        <ul>
                            {t(`${page}.${navigationItems[activeIndex].key}.body_type`) === "array" &&
                            t(`${page}.${navigationItems[activeIndex].key}.body`, { returnObjects: true }).map(list => (
                                <li>{list}</li>
                            ))}
                        </ul>
                            {t(`${page}.${navigationItems[activeIndex].key}.body_type`) === "text" &&
                            t(`${page}.${navigationItems[activeIndex].key}.body`)}
                    </div>
                </div>}
        </div>
    );
};

