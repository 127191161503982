import React from "react";

import { useTranslation } from "react-i18next";

// Custom Component
import BusinessSectorsCards from "Pages/Home/Components/BusinessSectorsCards/BusinessSectorsCards";
// Image
import {
    sectors_icon_estate,
    sectors_icon_industry,
    sectors_icon_others,
    sectors_icon_pharmacies,
    sectors_icon_retail,
    sectors_icon_school,
} from "Images/ImageComponents";

// Style
import "./business-sectors.css";

//  Translation tag
const homePagePartners = "home_page.business."

const sectors = [
    {
        index: 0,
        image: sectors_icon_retail,
        tag: homePagePartners + "retail",
    },
    {
        index: 1,
        image: sectors_icon_industry,
        tag: homePagePartners + "industry",
    },
    {
        index: 2,
        image: sectors_icon_estate,
        tag: homePagePartners + "estate",
    },
    {
        index: 3,
        image: sectors_icon_pharmacies,
        tag: homePagePartners + "pharmacies",
    },
    {
        index: 0,
        image: sectors_icon_school,
        tag: homePagePartners + "school",
    },
    {
        index: 0,
        image: sectors_icon_others,
        tag: homePagePartners + "others",
    },
];

export default function BusinessSectorsSection() {
    const { t, i18n } = useTranslation();
    return (
        <div className="business-sectors">
            <h1>{t(homePagePartners + "title")}</h1>
            <div className="business-sectors_icon-list">
                {sectors.map((sector) => (
                    <BusinessSectorsCards
                        index={sector.index}
                        image={sector.image}
                        tag={t(sector.tag)}
                    />
                ))}
            </div>
        </div>
    );
}
