import {
    createBrowserRouter,
    createRoutesFromElements,
} from "react-router-dom";
import { Route, RouterProvider } from "react-router-dom";

// Styles
import "./App.css";

// Custom Components
import Router from "./Common/Temp/Router/Router";

// Pages
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Careers from "./Pages/Careers/Careers";
import Contact from "./Pages/Contact/Contact";
import Construction from "./Pages/Construction/Construction";
import Solutions from "Pages/Solutions/Solutions";
import FinancialSystems from "Pages/FinancialSystems/FinancialSystems";
import DistributionSystems from "Pages/DistributionSystems/DistributionSystems";
import HumanResources from "Pages/HumanResources/HumanResources";
import FeaturedSystems from "Pages/FeaturedSystems/FeaturedSystems";
import Partners from "Pages/Partners/Partners";
import PartnersInfo from "Pages/PartnersInfo/PartnersInfo";
import RealState from "Pages/RealState/RealState";
import Commercial from "Pages/commercial/Commercial";
import Industrial from "Pages/Industrial/Industrial";
import CarRental from "Pages/CarRental/CarRental";
import Transportation from "Pages/Transportation/Transportation";
import SchoolPage from "Pages/SchoolPage/SchoolPage";
import FarmSector from"Pages/FarmSector/FarmSector";
import MaintenanceSector from "Pages/MaintenanceSector/MaintenanceSector";
import PharmaciesSector from "Pages/PharmaciesSector/PharmaciesSector";
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Router />}>
            <Route index element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/FinancialSystems" element={<FinancialSystems />} />
            <Route path="/DistributionSystems" element={<DistributionSystems />} />
            <Route path="/HumanResources" element={<HumanResources />} />
            <Route path="/FeaturedSystems" element={<FeaturedSystems />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/construction" element={<Construction />} />
            <Route path="/PartnersFinder" element={<Partners />} />
            <Route path="/PartnersInfo" element={<PartnersInfo />} />
            <Route path="/RealEstate" element={<RealState />} />
            <Route path="/commercial" element={<Commercial />} />
            <Route path="/industry" element={<Industrial/>} />
            <Route path="/carRental" element={<CarRental />} />
            <Route path="/transportation" element={<Transportation />} />
            <Route path="/school" element={<SchoolPage />} />
            <Route path="/farms" element={<FarmSector />} />
            <Route path="/pharmacy" element={<PharmaciesSector />} />
            <Route path="/maintenance" element={<MaintenanceSector />} />
        </Route>
    )
);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
