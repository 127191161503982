import React, {useEffect} from 'react'


// Custom Components
import SideNav from 'Common/Components/SideNav/SideNav';
import ContactForm from 'Common/Components/ContactForm/contactForm';
import SocialIcons from 'Common/Components/SocialIcons/socialIcons';

// Image & Context
import { distribution_systems_intro } from 'Images/ImageComponents';
import  navigationItems  from "./Context/items"

// Styles
import './distributionSystems.css'; 



export default function DistributionSystems ()  {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Distribution Systems | ASCON";
    });
    return (
        <div className="distribution-container">
            <img src={distribution_systems_intro} alt='distribution image' />
            
            <div className='distribution_content'>
                <div>
                    <div className='distribution-container_intro'>
                        <h2>Supply Chain and Distribution Systems</h2>
                        <p>Any product goes through a long journey before finally making it into the consumers’ hands. This journey starts with the raw materials manufactured for this product until the consumer purchases it.</p>
                        <p>The process includes steps like warehouse control, transportation, purchasing control, sales control, and others.</p>
                        <p>This is called the supply chain.</p>
                        <p>Supply chain managers have a difficult job. They have to manage all these different supply chain activities, including supply, transportation, warehousing, distribution, and other activities. That is the reason why this business needs a great deal of organization and effective communication.</p>
                        <p>Warehouse and Procurement programs help these managers do their jobs efficiently and to the fullest. The programs carry out all necessary operations and issue reports that ensure a smooth flow of operations and efficiency, thus increasing profits.</p>
                        <p>ASCON’s warehouse systems handle all supply chain operations efficiently.</p>
                    </div>
                    <SideNav navigationItems={navigationItems} page={"distribution_systems"}/>
                </div>
                <div className='content_right-side'>
                    <ContactForm />
                    <div className='contact-page_divider ' />
                    <SocialIcons />
                    <div className='contact-page_divider ' />
                </div>
            </div>
        </div>
    );
};

