import React ,{Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend';

// Custom Components
import LoadingComponent from 'Common/Components/LoadingCircle/LoadingCircle';

import './index.css';
import App from './App';


i18n
    .use(initReactI18next) 
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        fallbackLng: "en",
        detection:{
            order: ['cookie', 'path',  'localStorage',  'htmlTag',  'subdomain'],
            caches: ['cookie'],
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json',
        },
    });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={<LoadingComponent />}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
