import React , { useEffect } from 'react';
/*COMPONENTS IMPORTS*/
import FarmContent from './components/FarmContent';
import Sidebar from 'Common/Components/Sidebar/sidebar';
/*CSS IMPORTS*/
import './FarmSector.css';
import './IndexResponsive.css';

export default function FarmSector() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Farm & livestock sector | ASCON";
    });
    return (
        <div className='FarmSector'>
            <div className='FarmSector__content'>
                <FarmContent></FarmContent>
            </div>
            <div className='FarmSector__sidebar'>
                <Sidebar></Sidebar>
            </div>
        </div>
    )
}
