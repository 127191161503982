import React, {useEffect} from 'react'

// Custom Components
import Slider from './Components/Slider/Slider'
import SmartSolutionSection from './Sections/SmartSolution/SmartSolutionSection'
import PartnersSection from './Sections/Partners/PartnersSection'
import BusinessSectorsSection from './Sections/BusinessSectors/BusinessSectorsSection'
import AdvantagesSection from './Sections/Advantages/AdvantagesSection'
import Benefits from './Sections/Benefits/Benefits'
// Style
import "./indexResponsive.css"

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Home | ASCON";
    });
    return (
        <div>
            <Slider />
            <SmartSolutionSection />
            <PartnersSection />
            <Benefits />
            <AdvantagesSection />
            <BusinessSectorsSection />
        </div>
    )
}
