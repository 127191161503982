import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, 
            faCalendarCheck,
            faShareFromSquare,
            faBuilding,
            faGears,
            faSquare,
            faWrench,
            faSquarePen,
            faList} from '@fortawesome/free-solid-svg-icons';
import { faLinode } from '@fortawesome/free-brands-svg-icons';

const navigationItems = [
    { key: "featured_intro", 
        icon:<FontAwesomeIcon icon={faList} />,
    },
    { key: "equipment_management", 
        icon:<FontAwesomeIcon icon={faSquarePen} />
    },
    { key: 'workshop_maintenance', 
        icon:<FontAwesomeIcon icon={faWrench} />,
    },
    { key: 'transportation_management', 
        icon:<FontAwesomeIcon icon={faSquare} />,
    },
    { key: 'production_group', 
        icon:<FontAwesomeIcon icon={faGears} />,
    },
    { key: 'real_estate', 
        icon:<FontAwesomeIcon icon={faBuilding} />,
    },
    { key: 'school_management', 
        icon:<FontAwesomeIcon icon={faGraduationCap} />,
    },
    { key: 'more_group', 
        icon:<FontAwesomeIcon icon={faShareFromSquare} />,
    },
    { key: 'project_group', 
        icon:<FontAwesomeIcon icon={faCalendarCheck} />,
    },
    { key: 'customization_group', 
        icon:<FontAwesomeIcon icon={faLinode} />,
    },
];


export default navigationItems;